<template>
<div>

    <div class="filters">
        <div class="right-filter">
            <SwitchFilter @choose="(choice)=>{transType=choice}" :items="[{label:'واریز',value:'DEPSOIT'},{label:'برداشت',value:'WITHDRAW'}]" />
        </div>
        <div class="left-filter">
            <SwitchFilter @choose="choice=>coin=choice" :items="[{label:'ریالی',value:'TOMAN'},{label:'رمزارزی',value:''}]" />
            <DropFilter v-if="coin!='TOMAN'" @choose="(choice)=>{coin=choice.value}" :items="coinsList" />
        </div>
    </div>

    <!-- v-if="transType=='DEPSOIT' && currencyType=='RIAL'" -->
    <table>
        <tr class="table-header">
            <td v-if="coin!='TOMAN'">رمزارز</td>
            <td>نوع تراکنش</td>
            <td>مقدار <span class="tag" v-if="coin">({{$coins[coin].persianName}})</span></td>
            <td  class="resp-2" v-if="coin!='TOMAN'">آدرس کیف پول</td>
            <td class="resp-2" v-if="coin=='TOMAN' ">شماره کارت</td>
            <td class="resp-1">شناسه تراکنش</td>
            <td class="resp-1">زمان</td>
            <td class="resp-2">وضعیت</td>
        </tr>
        <tbody>
            <tr class="table-row" v-for="(transaction,index) in transactions" :key="index">
                <td v-if="coin!='TOMAN'">
                    <div class="coin-title">
                        <img class="coin-icon resp-3" :src="require('../../../../public/coins/'+transaction.relatedCoin+'.png')" />
                        <span>{{$coins[transaction.relatedCoin].persianName}}</span>
                        <span class="tag">({{$coins[transaction.relatedCoin].code}})</span>
                    </div>
                </td>
                <td :class="{'depsoit-buy':transType=='DEPSOIT','withdraw-sell':transType=='WITHDRAW'}">{{dict[transType]}}</td>
                <td dir='ltr' align='right'>{{$toLocal(transaction.amount,$decimal[transaction.relatedCoin])}}&nbsp;<span class="tag">{{!coin?$coins[transaction.relatedCoin].code:''}} </span> </td>
                <td class="resp-2">{{transaction.destinationWalletAddress || '-'}}</td>
                <td class="resp-1">{{transaction.hashCode || '-'}}</td>
                <td class="resp-1">{{$G2J(transaction.createdAtDateTime)}}</td>
                <td class="resp-2">
                    <div class="status">مشاهده جزئیات<img src="../../../assets/icons/success.svg" /></div>
                </td>
            </tr>
        </tbody>
    </table>
    <!-- 
    <table v-if="transType=='WITHDRAW' && currencyType=='RIAL'">
        <tr class="table-header">
            <td>نوع تراکنش</td>
            <td>مقدار <span class="tag">(تومان)</span></td>
            <td>شماره شبا</td>
            <td class="resp-1">شناسه پیگیری</td>
            <td class="resp-1">زمان</td>
            <td class="resp-2">وضعیت</td>
        </tr>
        <tbody>
            <tr class="table-row" v-for="(transaction,index) in filterTransactions()" :key="index">
                <td :class="{'depsoit-buy':transaction.transType=='DEPSOIT','withdraw-sell':transaction.transType=='WITHDRAW'}">{{dict[transaction.transType]}}</td>
                <td>{{transaction.amount}}</td>
                <td>{{transaction.shebaNumber}}</td>
                <td class="resp-1">{{transaction.check}}</td>
                <td class="resp-1">{{transaction.date}}</td>
                <td class="resp-2">
                    <div class="status">مشاهده جزئیات<img src="../../../assets/icons/success.svg" /></div>
                </td>
            </tr>
        </tbody>
    </table>

    <table v-if="currencyType=='CRYPTO'">
        <tr class="table-header">
            <td>رمزارز</td>
            <td>نوع تراکنش</td>
            <td>مقدار <span class="tag">({{coin.value}})</span></td>
            <td>کارمزد</td>
            <td class="resp-1">آدرس کیف پول</td>
            <td class="resp-1">زمان</td>
            <td class="resp-2">شناسه تراکنش/وضعیت</td>
        </tr>
        <tbody>
            <tr class="table-row" v-for="(transaction,index) in filterTransactions()" :key="index">
                <td>
                    <div class="coin-title">
                        <img class="coin-icon resp-3" :src="require('../../../assets/coins/'+$coinUnit[transaction.coinName]+'.svg')" />
                        <span>{{$coinLabel[transaction.coinName]}}</span>
                        <span class="tag">({{$coinUnit[transaction.coinName]}})</span>
                    </div>
                </td>
                <td :class="{'depsoit-buy':transaction.transType=='DEPSOIT','withdraw-sell':transaction.transType=='WITHDRAW'}">{{dict[transaction.transType]}}</td>
                <td>{{transaction.amount}}</td>
                <td>{{transaction.commission}}</td>
                <td class="resp-1">{{transaction.walletAddress}}</td>
                <td class="resp-1">{{transaction.date}}</td>
                <td class="resp-2">
                    <div class="status">مشاهده جزئیات<img src="../../../assets/icons/success.svg" /></div>
                </td>
            </tr>
        </tbody>
    </table> -->

</div>
</template>

<script>
import SwitchFilter from './SwitchFilter'
import DropFilter from './DropFilter'
import {
    statusLabel,
    statusColor

} from "@/library/reuseableFunction";

export default {
    name: 'Logins',
    components: {
        SwitchFilter,
        DropFilter,
    },
    props: {

    },
    watch: {

        coin() {
            this.withdrawList = []
            this.withdrawPage = 0
            this.withdrawLastPage = 1
            this.depositList = []
            this.depsoitPage = 0
            this.depsoitLastPage = 1
            this.getWithdrawHistory();
            this.getDepositHistory();
        }
    },
    computed: {
        transactions() {
            return this.transType == 'WITHDRAW' ? this.withdrawList : this.depositList
        },
        coinsList(){
            let coins= Object.entries(this.$coins).filter(coin => coin[1].code && coin[0] != 'TOMAN').map(coin => {
                return {
                    label: coin[1].persianName,
                    value: coin[0]
                }
            })
            coins.unshift(
                {
                    label: 'همه ارز ها',
                    value: ''
                }
            )
            return coins
        },
        coinsQuery() {
            let coinsStr = '?size=20'
            if (!this.coin) {
                Object.entries(this.$coins).filter(coin => coin[1].code && coin[0] != 'TOMAN').map(coin => {
                    coinsStr += '&relatedCoin=' + coin[0]
                })
                return coinsStr
            } else
                return coinsStr+'&relatedCoin=' + this.coin
        },

        statusLabel,
        statusColor
    },
    methods: {
        filterTransactions() {
            return this.transactions.filter(transaction => {
                if (transaction.transType == this.transType) {
                    if (this.coin != 'TOMAN') {
                        if (this.$coinUnit[transaction.coinName] == this.coin.value)
                            return transaction
                    } else
                        return transaction
                }
            })
        },
        async getWithdrawHistory() {
            const address = 'wallets/withdrawal-requests'
            if (this.withdrawPage <= this.withdrawLastPage) { //for scroll loading
                this.withdrawPage++ //for scroll loading
                this.loading = true
                let params = {
                    page: this.withdrawPage,
                }
              
                const [res, ] = await this.$http.get(`${address}${this.coinsQuery}`, {
                    params
                })
                if (res) {
                    this.withdrawList = [...this.withdrawList, ...res.content]
                    this.withdrawLastPage = res.totalPages

                }
                this.loading = false

            }

        },
        async getDepositHistory() {

            const address = 'wallets/deposit'
            if (this.depsoitPage <= this.depsoitLastPage) { //for scroll loading
                this.depsoitPage++ //for scroll loading
                this.loading = true
                let params = {
                    page: this.depsoitPage,
                }
              

                const [res, ] = await this.$http.get(`${address}${this.coinsQuery}`, {
                    params
                })
                if (res) {
                    this.depositList = [...this.depositList, ...res.content]
                    this.depsoitLastPage = res.totalPages
                }
                this.loading = false

            }
        },
    },
    mounted() {
        this.getWithdrawHistory()
        this.getDepositHistory()
    },
    data() {
        return {
            withdrawList: [],
            depositList: [],
            loading: false,
            depsoitPage: 0,
            withdrawPage: 0,
            depsoitLastPage: 1,
            withdrawLastPage: 1,
            transType: 'DEPSOIT',
            dict: {
                'WITHDRAW': 'برداشت',
                'DEPSOIT': 'واریز'
            },
            coin: 'TOMAN'

        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.left-filter {
    flex-grow: 1;
    max-width: 280px;
}

.mar {
    width: 600px;
}

@media(max-width:1200px) {

    .resp-1 {
        display: none;
    }
}

@media(max-width:900px) {
    .resp-2 {
        display: none;
    }
}

@media(max-width:600px) {
    .resp-3 {
        display: none;
    }
}
</style>
