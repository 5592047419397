<template>
<ModalCard @close="$emit('modalToggle','')">
    <template #title>
        واریز به کیف پول {{$coins[relatedCoin].persianName}}
    </template>
    <template #body>
        <div v-if="!selected_net" class="crypto-network">
            <div class="text"> شبکه انتقال ارز را اتنخاب کنید</div>
            <div class="toggle-comp">
                <DropDown v-if="netsList" class="base-coin"
                 @choose="(net)=>{cryptoNetworkIndex=netsList.indexOf(netsList.find(item=>item.value==net))}" :items="netsList" />
            </div>
            <button class="modal-submit-btn" @click="selectNet()">تایید</button>
        </div>
        <template v-else>

            <div class="text">
                آدرس کیف پول شما در زیر قابل مشاهده است.برای واریز بیت کوین به این کیف ,میتوانید از این آدرس استفاده کنید
            </div>
            <div class="qr-code">
                <img class="qr-img" :src="'data:image/png;base64,'+qrCode" alt="">
            </div>

            <LabelInput :value='address' label='آدرس کیف پول' :disabled="true" />

            <div class="warning">
                <img src="../../assets/icons/red-warn.svg" />
                <div> این کیف پول فقط برای واریز {{$coins[relatedCoin].persianName}} میباشد.واریز هر ارز دیگری به این آدرس موجب از دست رفتن دارایی شما میشود</div>
            </div>

            <button class="modal-submit-btn" @click.prevent="checkWallet" :disabled="(txIdIsNeeded && !txid && !enable) || selected_net==''">بررسی واریز </button>
        </template>
    </template>

</ModalCard>
</template>

<script>
import DropDown from '../tools/DropDown'
import ModalCard from './ModalCard'
export default {
    name: 'CryptoDepsoit',
    components: {
        ModalCard,
        DropDown
    },
    props: ['relatedCoin', 'isExchange'],
    data() {
        return {
            amount: '',
            address: '',
            qrCode: '',
            tag: '',
            txid: '',
            token: false,
            alertLink: false,
            drop: false,
            selected_net: "",
            txIdIsNeeded: false,
            enable: false,
            coins: [],
            cryptoNetworkIndex: 0,
            nets: []
        }
    },
    computed: {
        netsList() {
            return this.nets.map(net => {
                console.log({
                    label: net.tokenType,
                    value: net.tokenType
                })
                return {
                    label: net.tokenType,
                    value: net.tokenType
                }
            })
        },
    },
    methods: {
        copyLink() {
            navigator.clipboard.writeText(this.address);
            this.alertLink = true
            setTimeout(() => {
                this.alertLink = false;
            }, 1500);
        },
        closeModale() {
            this.$emit('closeModaleEmit', this.close)
        },
        changeNetwork(e) {
            this.token = e
            this.getQR()
        },
        Copy(e) {
            navigator.clipboard.writeText(e)
            alert('آدرس کپی شد')
        },
        async checkWallet() {
            this.state.loading = true
            // const token = this.token ? 'ERC20' : 'TRC20'
            let query = {
                relatedCoin: this.relatedCoin,
                tokenType: this.selected_net,
                walletType: (this.isExchange ? 'EXCHANGE' : 'P2P')
            }
            if (this.txIdIsNeeded) {
                query.txid = this.txid
            }
            const res = await this.$axios.get('/wallets/check-confirmed-transaction', {
                params: query
            })
            let text
            if (res.baseDTO.amount) {
                text = `مقدار ${this.$toLocal(res.baseDTO.amount) || 0} ${this.$coins[this.relatedCoin].persianName} واریز شده و تایید ${res.baseDTO.transactionIsDone ? 'شده' : 'نشده'}`
            } else {
                text = 'مقدار واریزی شناسایی نشد'
            }
            this.$error('', text, res.baseDTO.transactionIsDone ? 'success' : 'info')
        },
        selectNet() {
            console.log(this.cryptoNetworkIndex)
            this.selected_net = this.nets[this.cryptoNetworkIndex].tokenType
            this.txIdIsNeeded = this.nets[this.cryptoNetworkIndex].txIdIsNeeded
            this.enable = this.nets[this.cryptoNetworkIndex].depositEnable || true
            this.drop = false
            if (this.enable) {
                this.getQR()
            }

        },
        async getData() {
            this.state.loading = true
            const res = await this.$axios.get('/coins/details')
            this.coins = res.content
            const coinData = this.coins.find(coin => coin.coin == this.relatedCoin)
            this.nets = coinData.tokenTypeDetails
            this.wage = coinData.transferFee

        },
        async getQR() {
            this.state.loading = true
            const token = this.selected_net
            let params = {
                relatedCoin: this.relatedCoin
            }
            if (this.relatedCoin === 'TETHER') {
                params.tokenType = token
            }
            console.log(params)
            const res = await this.$axios('/wallets/customer/wallet-address?walletType=' + (this.exchange ? 'EXCHANGE' : 'P2P') + '&tokenType=' + token, {
                params
            }).catch(() => {
                this.closeModale()
            })
            if (res) {
                this.address = res.baseDTO.address
                this.qrCode = res.baseDTO.qrCode
                this.tag = res.baseDTO.tag
            }
        },
    },
    mounted() {
        this.getData()
    }
}
</script>

<style scoped>
button {
    width: 100%;
}

.crypto-network {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.text {
    font-size: clamp(12px, 1.3vw, 14px);
    color: var(--modal-text)
}

.qr-code {
    display: flex;
    justify-content: center;
}

.qr-code>img {
    max-width: min(60%, 200px);
}

.warning {
    color: #FF2D78;
    font-size: clamp(11px, 1.1vw, 12px);
    font-family: 'YekanLight';
    display: flex;
    align-items: flex-start;
    column-gap: 7px;
}

.field-label {
    font-size: clamp(11px, 1.1vw, 12px);
    font-family: 'LightYekan';
}

.depsoit,
.depsoit * {
    display: flex;
    justify-content: space-between;
    column-gap: 16px;
    font-size: clamp(12px, 1.4vw, 14px);
    font-family: 'YekanLight';
    color: var(--modal-text)
}
</style>
