<template>
<div class="p2p">
    <div class="head-bar">
        <div class="label-value">
            <div class="label">بازار</div>
            <div class="value">{{$coins[tradeFrom].persianName}} - {{$coins[tradeTo].persianName}} </div>
        </div>
        <div class="label-value">
            <div class="label">درصد تغییرات</div>
            <div class="value change" dir="ltr"><span v-if="crypto.last24HourChange>0">+</span>
                <span v-if="crypto.last24HourChange<0">-</span>{{$toLocal(Math.abs(crypto.last24HourChange),2)}}%</div>
        </div>
        <div class="label-value">
            <div class="label">بیشترین </div>
            <div class="value">{{$toLocal(crypto.maxPrice,$decimal[tradeTo])}} </div>
        </div>
        <div class="label-value">
            <div class="label">کمترین </div>
            <div class="value">{{$toLocal(crypto.minPrice,$decimal[tradeTo])}} </div>
        </div>
        <div class="label-value">
            <div class="label">آخرین </div>
            <div class="value">{{$toLocal(crypto.lastPrice,$decimal[tradeTo])}} </div>
        </div>
        <div class="label-value">
            <div class="label">حجم </div>
            <div class="value">{{$toLocal(crypto.last24HourVolume,$decimal[tradeTo]) }} </div>
        </div>
    </div>
    <TradingView :key="tradeFrom && state.lightTheme" :cryptoSymbol="$coins[tradeFrom].code" />
    <div class="middle-section">
        <LastOrdersAndCoinsTable :cryptos="cryptos"  />
        <TradeComp :minAmount="crypto.minAmount" :maxAmount="crypto.maxAmount"  :shopInfo="shopInfo" :cryptos="cryptos" @changeBaseCoin="value=>baseCoin=value" />
        <OrderBookTable :tableContentsBuy="tableContentsBuy" :tableContentsSell="tableContentsSell"  />
    </div>
    <FooterTable />
</div>
</template>

<script>
import FooterTable from './FooterTable'
import TradeComp from './TradeComp'
import TradingView from './TradingView'
import OrderBookTable from './OrderBookTable'
import LastOrdersAndCoinsTable from './LastOrdersAndCoinsTable'
import {
    tradeTo,
    tradeFrom,
    Loop
} from "@/library/reuseableFunction";
export default {
    name: 'P2P',
    components: {
        TradingView,
        TradeComp,
        FooterTable,
        LastOrdersAndCoinsTable,
        OrderBookTable
    },
    data() {
        return {
            tableContentsSell: [],
            tableContentsBuy: [],
            coinsDetails: [],
            shopInfo: {
                minSell: 0,
                maxBuy: 0
            },

            baseCoin: 'TETHER',
            crypto: {},
            baseCoinDict: {
                'TETHER': 'تتر',
                'TOMAN': 'تومان'
            },
            cryptos:[]
        }
    },
    computed: {
        tradeTo,
        tradeFrom
    },
    watch: {
        'tradeTo': function () {
            this.getCoins()
        }
    },
    async mounted() {
        await this.getCoinsDetails()
        this.loading = true
        if (this.state.loop) {
            this.loop1 = new Loop(this.getCoins, 5000, 'realPrice' + (this.tradeFrom))
            this.loop2 = new Loop(this.getMarketDepth, this.state.time, 'marketDepth:' + this.tradeFrom + '_' + this.tradeTo)

            this.loop1.start()
            this.loop2.start()
        } else {
            this.getCoins()
            this.getMarketDepth()

        }

    },
    beforeDestroy() {
        this.loop1.stop()
        this.loop2.stop()
    },
    methods: {

        async getCoins() {
            let res = await this.$axios.get('/trades/market-info', {
                params: {
                    weeklyDataIncluded: false,
                }
            }).then(response => res = response.content)
            if (res) {
                this.cryptos = res.filter(crypto => crypto.marketType.includes(this.tradeTo))
                this.setCrypto()
            }
        },
        async getCoinsDetails() {
            const res = await this.$axios.get('/coins')
            this.coinsDetails = res.content

        },
        async getMarketDepth() {

            const [res, ] = await this.$http.get('/orders/order-book', {
                params: {
                    marketType: this.tradeFrom + '_' + this.tradeTo
                }
            })
            if (res) {
                this.tableContentsSell = res.sellOrderBook.slice(0, 11).sort((a, b) => {
                    a.unitPrice > b.unitPrice
                })
                this.tableContentsBuy = res.buyOrderBook.slice(0, 11).sort((a, b) => {
                    return a.unitPrice > b.unitPrice
                })
                this.shopInfo.minSell = res.sellOrderBook.length ? res.sellOrderBook[0].unitPrice : 0
                this.shopInfo.maxBuy = res.buyOrderBook.length ? res.buyOrderBook[0].unitPrice : 0
            }
            this.loading = false
        },
        setCrypto() {
            if (this.cryptos) {
                console.log('dfssdf')
                this.crypto = this.cryptos.find(crypto => crypto.marketType.includes(this.tradeFrom))
                if (this.crypto && this.coinsDetails) {
                    this.crypto.minAmount = this.coinsDetails.find(coin => coin.coin == this.tradeFrom).minAmount
                    this.crypto.maxAmount = this.coinsDetails.find(coin => coin.coin == this.tradeFrom).maxAmount
                }

            }

        },

       
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.label {
    color: var(--P2P-headbar-label);
    font-size: clamp(12px, 1.3vw, 13px);
    font-family: 'YekanLight';
}

.value {
    color: var(--P2P-headbar-value);
    font-size: clamp(13px, 1.4vw, 14px);
    font-weight: bold;
}

.head-bar {
    display: flex;
    align-items: center;
    column-gap: 8px;
    justify-content: space-between;
    flex-wrap: wrap;
    border-radius: 20px;
    background: var(--P2P-headbar-bg);
    padding: 17px clamp(27px, 3.5vw, 48px);
}

.label-value {
    display: flex;
    align-items: center;
    column-gap: 8px;
}

.change {
    color: var(--P2P-headbar-change);
    font-weight: normal;
}

.p2p {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    row-gap: 16px;

}

.middle-section {
    display: flex;
    column-gap: 16px;
    row-gap: 16px;
    justify-content: space-between;
    flex-wrap: wrap;
}

.light-font {
    font-family: 'YekanLight';
}

.green {
    color: #80B73B;
}

.red {
    color: #DD1B60;
}
</style>
