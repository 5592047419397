<template>
  <div class="support">
     
      <div class="description-sec">
        <h1 class="header">
                سوالات؟ &nbsp; با &nbsp; پشتیبانی &nbsp; مشتری &nbsp; 24/7 &nbsp; ما &nbsp; تماس &nbsp; بگیرید
        </h1>
        <h1 class="sub-header"> 
             تجارت &nbsp;در&nbsp; هر &nbsp;زمان،&nbsp; هر &nbsp;مکان
        </h1>
        <p class="text">اپلیکیشن و وب سایت 700 به شما این امکان را می دهد
             که تجارت را به راحتی شروع کنید. اپلیکیشن و 
             وب سایت 700 به شما این امکان را می دهد که تجارت را به راحتی شروع کنید.
        </p>
        <div class="social-medias-icons">
            <div class="icon-container" v-for="(icon,index) in icons" :key="index">
                <inline-svg class="icon" :src="require('../../../assets/icons/'+icon+'.svg')"/>
            </div>
        </div>

      </div>
       <div class="support-bg">
        <img src="../../../assets/public/homepage-support-bg.png"/>

      </div>
   
  </div>
</template>

<script>
export default {
  name: 'Support',
  props: {
  },
  data(){
      return {
          icons:[
              'instagram',
              'whatsapp',
              'twitter',
              'telegram',
              'gmail',
              'linkedin',
              'facebook',
              'google',
          ]
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">





.header{
    font-size: clamp(16px,1.7vw,18px);
    font-weight: bold;
    color:var(--publicpage-support-header)
}
.sub-header{
    font-size: clamp(16px,1.7vw,18px);
    color:var(--publicpage-support-subheader);
}

.text{
    font-size: clamp(13px,1.3vw,14px);
    color: var(--publicpage-support-text);
}


.support {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 20px;
    direction: ltr;
}
.icon{
    stroke: var(--publicpage-support-icon);   
}

.icon-container{
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F8F8FF;
    border-radius: 50px;
    width: 32px;
    height: 32px;
}

.social-medias-icons{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.description-sec{
    
    max-width: 387px;
    flex-grow: 1;
    direction: rtl;
}


.support-bg{
    display: flex;
    flex: 4.5;
    flex-basis: 600px;
    position: relative;
    max-width:739px;

}

.support-bg > img{
    width: 100%;
}

</style>
