<template>
<div class="label-input">
    <label :class="{disabled:disabled}">{{label}}</label>
    <div class="input-container">
        <input :class="{disabled:disabled}" id='calendar-input' />
        <div class="input-icon">
            <inline-svg class="calendar-icon" :src="require('../../assets/icons/calendar.svg')" />
        </div>
        <date-picker class="calender-comp" color='#143B74'
         v-model="date" 
         custom-input="#calendar-input" 
        :disabled="disabled"
        :type="isBirth?'date':'datetime'"
        :format="$dateFormat['en']"
        :display-format="$dateFormat[isBirth?'fa':'faDateTime']"
        :max="(isBirth)?$jmoment().format($dateFormat['en']):''"
        :min="(isBirth)?'':$jmoment().format($dateFormat['en'])"
           ></date-picker>


    </div>

</div>
</template>

<script>
import DatePicker from 'vue-persian-datetime-picker'
export default {
    name: 'Calendar',
    components: {
        DatePicker
    },
    props: {
        label: String,
        type: String,
        value: String,
        disabled: Boolean,
        isBirth:Boolean
    },
    watch: {
        'date'() {
            if (this.date)
                this.$emit('input', this.date)
        },
        'value'() {
            this.date = this.value
        }
    },
    data() {
        return {
            passShow: false,
            date: this.value
        }
    },
}
</script>

<style scoped>
.label-input {
    margin-top: 6px;
    /* width: 100%; */
    border: 0.3px solid var(--label-input-border);
    border-radius: 50px;
    display: flex;
    text-align: right;
    /* flex: 1; */
}

input {
    height: 42px;
    flex-grow: 1;
    size: 1;
    width: 60px;
    background: var(--label-input-bg);
    color: var(--label-input-text);
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;

}

.calendar-icon {
    stroke: var(--calendar-icon);
}

.input-container {
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;
}

label {
    border-radius: 0px 50px 50px 0px;
    font-size: clamp(12px, 1.3vw, 13px);
    background: var(--label-input-bg);
    color: var(--label-input-text);

    display: flex;
    padding: 0px 10px;
    align-items: center;
    flex-grow: 1;
    white-space: nowrap;

}

.input-icon {
    display: flex;
    align-items: center;
    position: absolute;
    left: 10px;
    cursor: pointer;

}

.disabled {
    background: var(--label-input-disabled);

}

.calender-comp {
    opacity: 1;
    direction: ltr;
    color: black;
    ;
    position: absolute;
    height: auto;
    width: 100%;
}
</style>
