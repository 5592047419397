<template>
  <div class="contact-us">
       <div class="head-part">
            <div class="head-text">
                <div class="page-title">700 صرافی</div>
                <p >لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.               </p>
            </div>
            <div class="img-container ">
                <img src="../../assets/public/contactus-img.png" class="img"/>
            </div>
        </div>
        <div class="contact-medias">
            <div class="medias-info">
                <div>
                    <div class="medias-section-title">ما را در شبکه های اجتماعی دنبال کنید</div>
                    <div class="medias-icon">
                        <div class="media-icon-container" v-for="(icon,index) in icons" :key="index">
                        <inline-svg class="media-icon"  :src="require('../../assets/icons/'+icon+'.svg')"/>

                        </div>
                    </div>

                </div>
                <p class="contact-guide-text">با توجه به حجم بالای تماس ها ممکن است امکان پاسخگویی به تمام درخواست های شما وجود نداشته باشد. لذا خواهشمند است تا جای ممکن سوالات و مشکلات خود را با پشتیبانی آنلاین یا از طریق ایمیل به این بخش مطرح کنید. </p>
                <div class="address">
                    <div class="address-yellow-dot"/>
                    <div class="address-text">{{info.address}}</div>
                </div>
                <div class="address">
                    <div class="address-yellow-dot"/>
                    <div class="address-text">{{info.number}}</div>
                </div>
                 <div class="address">
                    <div class="address-yellow-dot"/>
                    <div class="address-text">{{info.email}}</div>
                </div>
                <div class="alert-section">
                    <inline-svg :src="require('../../assets/icons/info-circle.svg')"/>
                    <p class="alert-text">تیم (...) هیچ‌گونه فعالیت پشتیبانی در پیام‌‌رسان‌های تلگرام و واتس آپ ندارد و تنها کانال رسمی اطلاع رسانی به آدرس ...@ در تلگرام می‌باشد.</p>
                </div>
                <img class="location-img" src="../../assets/public/location-map.png"/>
            </div>
            <div class="form-data-card">
                <div class="card-title">ارتباط با بخش امور مشتریان</div>
                <LabelInput @input="value=>{}"   label="آدرس ایمیل"/>
                <textarea class="address-input"   rows="5" placeholder="درخواست خود را ثبت کنید"/>     
                <div class="captcha-container">
                    <img src="../../assets/public/captcha-code.png"/>      
                </div>
               <LabelInput   type='securityCode' label="کد امنیتی"/>
              
              <button class="submit-btn">ارسال</button>
              <div class="suggest">حساب کاربری دارید؟<a>وارد شوید</a></div>
            </div>
        </div>
      
  </div>
</template>

<script>
export default {
  name: 'ContactUs',
  props: {
    msg: String
  },
    methods:{
          async getInfo() {
            const res = await this.$axios.get('/exchange-info')
            this.info = res.baseDTO || {
                address: '',
                appAndroid: '',
                appIOS: '',
                email: '',
                instagram: '',
                linkedin: '',
                number: '',
                telegram: '',
                twitter: ''
            }
        },

  },
  data(){
      return {
        info:{},

           icons:[
              'instagram',
              'whatsapp',
              'twitter',
              'telegram',
              'gmail',
              'linkedin',
              'facebook',
              'google',
          ]
      }
  },
    mounted(){
      this.getInfo()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
//form-data-card
.form-data-card{
   
    padding: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    width: 100%;
    position: relative;
    // background: greenyellow;
    max-width: 536px;
    z-index: 1;
    border-radius: 20px;
}
.form-data-card > *{
    z-index: 2;
}


.suggest{
    font-size:clamp(10px,1.1vw,12px);
    color:var(--contactus-suggest);
    text-align: center;
}
.form-data-card::before{
    background: url('../../assets/public/coloryBg1.png');
    background-size: cover;
    content:'';
    z-index: 0;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.4;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    
}

.submit-btn{
      border-radius: 50px;
    height: 41px;
    background:var(--authcard-submit-btn);
    color: #F8F8FF;
}

.card-title{
    text-align: center;
    font-weight: bold;
    font-size: clamp(14px,1.5vw,16px);

}

.captcha-container{
    display: flex;
    justify-content: center;
}







.contact-medias{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    row-gap: 40px;
    width: 100%;
    margin-top:100px;
    justify-content: space-between;
}
.img-container > img{
   width: 100%;
   max-width: 690px;
}
.img-container{

    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    min-width: 200px;
}
.head-part{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 40px;
}


.address-input{
    background:var(--textarea-bg) ;

}
.contact-us{
    width: 85%;
    display: flex;
     margin-top: 60px;

    margin-bottom:100px;
    flex-direction: column;
    align-items: center;
}
.medias-section-title{
    color:var(--contactus-medias-title);
    font-weight: bold;
    font-size: clamp(20px,2.2vw,24px);
}
.medias-icon{
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: space-between;
}

.alert-text{
    font-size: clamp(12px,1.3vw,13px);
    color: var(--contactus-alert-text);
}

.alert-section{
    display: flex;
    align-items: center;
    column-gap: 12px;
}

.location-img{
    width: 100%;
    height: auto;
    border-radius: 20px;
}
.address{
    display: flex;
    column-gap: 8px;
    align-items: center;
}
.address-text{
    color:var(--contactus-address-text);
    font-size: clamp(14px,1.5vw,16px);
}
.address-yellow-dot{
    width: 8px;
    height: 8px;
    background: #FFBF00;
    border-radius: 50%;
}

.media-icon-container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background: var(--contactus-media-icon-bg);
}

.medias-info{
    display: flex;
    flex-direction: column;
    min-width: 250px;
    align-items: flex-start;
    max-width: 503px;
    overflow: hidden;


}

.media-icon{
    stroke:var(--contactus-media-icon)

}
.page-title{
    color: var(--contactus-page-title);
    font-size: clamp(30px,4vw,48px);
}

.contact-guide-text{
    font-size: clamp(14px,1.6vw,16px);
    color:var(--contactus-guide-text)
}
.head-text{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-size: clamp(14px,1.4vw,16px);
    flex-basis:300px;
    color:var(--contactus-head-text);
    row-gap: 24px;
}



</style>
