<template>
<ModalCard @close="$emit('modalToggle','')">
    <template #title>
        شناسایی دو عاملی
    </template>
    <template #body>
        <div class="text" v-if="twoStep === 'GOOGLE_AUTHENTICATOR'">
            برای فعالسازی شناسایی دو عاملی,ابتدا برنامه Google Authenticator را دانلود نموده و سپس کد QR زیر را توسط آن اسکن نمایید
        </div>
        <div class="text" v-else-if="twoStep === 'EMAIL'">
            برای فعالسازی شناسایی دو عاملی,کد شش رقمی ارسال شده به آدرس ایمیل خود را وارد نمایید
        </div>
        <div class="text" v-else-if="twoStep === 'SMS'">
            برای فعالسازی شناسایی دو عاملی,کد شش رقمی ارسال شده به شمار موبایل خود را وارد نمایید
        </div>
        <div class="qr-code" v-if="twoStep === 'GOOGLE_AUTHENTICATOR'">
            <img :src="'data:image/png;base64,'+qrCode" alt="">
        </div>
        <div class="text" v-if="twoStep === 'GOOGLE_AUTHENTICATOR'">
            پس از اسکن بارکد ,مورد مربوط به 700 در برنامه شما ایجاد خواهد شد.برای تایید صحت فرآیند,کدی که در اپ خود مشاهده می کنید را در کادر زیر وارد نمایید
        </div>
        <VerifyCodeInput @change="e=>otp.code=e" @complete="submit()" :num="6" />
        <span v-if="twoStep !== 'GOOGLE_AUTHENTICATOR'" class="countdown">{{Math.floor(countDown/60)}}:{{countDown%60}}</span>
       
        <button class="auth-submit-btn" v-if="countDown>0" :class="{disabled:otp.code.length!==6}" :disabled="otp.code.length!==6" @click="submit()">تایید</button>
        <button class="auth-submit-btn" v-if="countDown==0"  @click="send()">ارسال مجدد</button>

    </template>

</ModalCard>
</template>

<script>
const verifyTypes = {
    'EMAIL': 'ایمیل',
    'SMS': 'پیامک',
    'GOOGLE_AUTHENTICATOR': 'Google Authenticator'
}
import ModalCard from './ModalCard'
import VerifyCodeInput from '../tools/VerifyCodeInput'
export default {
    name: 'OtpVerify',
    components: {
        ModalCard,
        VerifyCodeInput
    },
    props: ['twoStep', 'qrCode', 'qrLink', 'mobile'],

    data() {
        return {
            otp: {
                code: ''
            },
            countDown:120,
            timeOut:''
        }
    },
    methods: {
          countDownTimer() {
            if (this.countDown > 0) {
                this.timeOut = setTimeout(() => {
                    this.countDown--
                    this.countDownTimer()
                }, 1000)
            } else {
                this.$error('زمان شما به اتمام رسید', '')
            }
        },
        async send() {
            this.reset()
            this.state.loading = true
            const a = {
                EMAIL: 'ایمیل',
                SMS: 'شماره'
            }
            const res = await this.$axios.post(`/users/send-verification-code?twoStepAuthorization=${this.twostep}`)
            if (res.message === 'Verification Code Sent') {
                this.$error('', 'کد به ' + a[this.twoStep] + ' شما ارسال شد', 'success')
                if (this.countDown === 0)
                    this.countDown = 120
                this.countDownTimer()
            }
        },
        async submit() {

            if (this.otp.code.toString().length === 6) {
                this.state.loading = true
                const [res, ] = await this.$http.post(`/users/change-otp?twoStepAuthorization=${this.twoStep}&code=${this.otp.code}`)
                if (res) {
                    this.$emit('modalToggle', '')
                    this.$emit('submit', 'شناسایی دو مرحله ای شما به ' + verifyTypes[this.twoStep] + ' تغییر یافت.')
                }
            }
        },
    },
     mounted() {
        if (this.twoStep !== 'GOOGLE_AUTHENTICATOR')
         this.countDownTimer()
    },
    beforeDestroy() {
        clearTimeout(this.timeOut)
    },
}
</script>

<style scoped>
.text {
    font-size: clamp(12px, 1.3vw, 14px);
    color: var(--modal-text)
}

.disabled{
    opacity: 0.7;
}
.qr-code {
    display: flex;
    justify-content: center;
}

.qr-code>img {
    max-width: min(60%, 200px);
}
</style>
