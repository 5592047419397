<template>
<AuthCard @close="$emit('authModalToggle','')">
    <template #title>
        فراموشی رمز عبور
    </template>
    <template #body>
        <div class="text">
            آدرس ایمیلی که با آن در سایت ثبت نام کردید را وارد کنید
        </div>
        <LabelInput @input="value=>email=value" label="آدرس ایمیل" />
        <button class="auth-submit-btn" :class="{disabled:!email}" :disabled="!email" @click="send()">ارﺳﺎل اﯾﻤﯿﻞ ﺑﺎزﯾﺎﺑﯽ رﻣﺰ ﻋﺒﻮر </button>
    </template>

</AuthCard>
</template>

<script>
import AuthCard from './AuthCard'
const verifyTypes = {
        EMAIL: 'Email',
        SMS: 'SMS',
        GOOGLE_AUTHENTICATOR: 'GoogleAuth',
    }
export default {
    name: 'ForgetPassEmail',
    components: {
        AuthCard
    },
    data() {
        return {
            goto: {
                EMAIL: '/reset-pass-email',
                SMS: '/reset-pass-sms',
                GOOGLE_AUTHENTICATOR: '/reset-pass-google-auth',
            },
            email: '',
            emailError: '',

        }
    },
    methods: {
        checkEmail() {
            if (!this.email) {
                this.emailError = 'ایمیل اجباری';
            } else if (!this.validEmail(this.email)) {
                this.emailError = 'ایمیل صحیح وارد نمایید';
            } else {
                this.emailError = ''
            }
            if (!this.emailError.length) {
                return true;
            }
        },
        validEmail(email) {
            let re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
            return re.test(email);
        },
        receive(e) {
            this.email = e
            this.checkEmail(e)
        },
        async send() {
            if (this.checkEmail(this.email)) {
                await this.checkEmail()
                this.state.loading = true
                const res = await this.$axios.post('/users/forget-password?email=' + this.email)
                this.$cookies.set('username', this.email)
                this.state.userInfo.email = this.email
                if (res.baseDTO) {
                    this.state.userInfo.mobilenumber = res.baseDTO.starTwoStepAuthentication || '';
                    this.state.userInfo.towStepAuthenticationType = verifyTypes[res.baseDTO.towStepAuthenticationType];
                } else {
                    this.state.userInfo.towStepAuthenticationType = 'Email'
                }
                this.$emit('authModalToggle','ForgetPass')
            }
        }
    }
}
</script>

<style scoped>
.text {
    font-size: 12px;
    color: var(--modal-text)
}
.disabled{
  opacity: 0.7;
}
</style>
