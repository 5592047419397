<template>
<div class="chart">
    <apexchart type="donut" :key='series.length+""+change' width="220" :options=" chartOptions" :series="series"></apexchart>
</div>
</template>

<script>
export default {
    name: 'Chart',
    props: ['wallets'],

    data() {
        return {
            change: 0,

            series: [],
            chartOptions: {
                tooltip: {
                    enabled: false
                },
                xaxis: {
                    type: "numeric"
                },
                labels: [],

                dataLabels: {
                    enabled: false,
                },
                states: {
                    hover: {
                        filter: {
                            type: 'lighten',
                        }
                    },
                },

                legend: {
                    show: false
                },
                stroke: {
                    show: true,
                    curve: 'smooth',
                    lineCap: 'round',
                    width: 0,
                },
                plotOptions: {
                    pie: {
                        expandOnClick: false,

                        donut: {
                            size: '93%',

                            labels: {
                                show: true,

                                name: {
                                    show: true,
                                    fontSize: '12px',
                                    fontFamily: 'Yekan',
                                    fontWeight: 600,
                                    offsetY: -17,
                                    formatter: function (val) {
                                        return val.name || val
                                    }
                                },
                                value: {
                                    show: true,
                                    fontSize: '12px',
                                    fontFamily: 'Yekan-en',
                                    fontWeight: 400,
                                    offsetY: 10,
                                    formatter: function (val) {
                                        return parseFloat(val).toLocaleString()
                                    }
                                },
                                total: {
                                    show: true,
                                    showAlways: false,
                                    label: 'ارزش کل دارایی',
                                    fontSize: '12px',
                                    fontFamily: 'Yekan',
                                    fontWeight: 600,
                                    formatter: function (w) {
                                        w = w.globals.seriesTotals.reduce((a, b) => a + b)
                                        return w.toLocaleString() + '\n' + 'تومان'
                                    }
                                }
                            }
                        }
                    }
                },

        },
        // chartOptions: {
        //     chart: {

        //         type: 'radialBar',
        //     },
        //     plotOptions: {
        //         radialBar: {
        //             inverseOrder: false,
        //             startAngle: 0,
        //             endAngle: 360,
        //             offsetX: 0,
        //             offsetY: 0,
        //             hollow: {
        //                 margin: 5,
        //                 size: '50%',
        //                 background: 'transparent',
        //                 image: undefined,
        //                 imageWidth: 150,
        //                 imageHeight: 150,
        //                 imageOffsetX: 0,
        //                 imageOffsetY: 0,
        //                 imageClipped: true,
        //                 position: 'front',
        //                 dropShadow: {
        //                     enabled: false,
        //                     top: 0,
        //                     left: 0,
        //                     blur: 3,
        //                     opacity: 0.5
        //                 }
        //             },
        //             track: {
        //                 show: true,
        //                 startAngle: undefined,
        //                 endAngle: undefined,
        //                 background: '#f2f2f2',
        //                 strokeWidth: '60%',
        //                 opacity: 0.4,
        //                 margin: 5,
        //                 dropShadow: {
        //                     enabled: false,
        //                     top: 0,
        //                     left: 0,
        //                     blur: 3,
        //                     opacity: 0.5
        //                 }
        //             },
        //             dataLabels: {
        //                 show: true,
        //                 name: {
        //                     show: true,
        //                     fontSize: '19px',
        //                     fontFamily: undefined,
        //                     fontWeight: 600,
        //                     color: undefined,
        //                     offsetY: -10,
        //                     formatter: function (val) {
        //                         return val.name || val
        //                     }
        //                 },
        //                 value: {
        //                     show: true,
        //                     fontSize: '14px',
        //                     fontFamily: undefined,
        //                     fontWeight: 700,
        //                     color: '#9AACFF',
        //                     offsetY: 5,
        //                     formatter: function (val) {
        //                         return Math.floor(val).toLocaleString()
        //                     }
        //                 },
        //                 total: {
        //                     show: true,
        //                     label: '700',
        //                     color: '#9AACFF',
        //                     fontSize: '13px',
        //                     fontFamily: undefined,
        //                     fontWeight: 500,
        //                     formatter: function (w) {
        //                         return Math.floor(w.globals.seriesTotals.reduce((a, b) => {
        //                             return a + b
        //                         }, 0)).toLocaleString()
        //                     }
        //                 }
        //             }
        //         }
        //     },
        //     labels: ['Apples', 'Oranges', 'Bananas', 'Berries'],
        //     stroke: {
        //         show: true,
        //         curve: 'smooth',
        //         lineCap: 'round',
        //         colors: undefined,
        //         width: 0,
        //         dashArray: 0,
        //     },

        // },

    }

},
mounted() {
        this.chart()

    },
    watch: {
        wallets() {
            this.chart()
        }
    },
    methods: {
        chart() {

            let index = 0
            Object.entries(this.wallets).forEach((e) => {
                    this.chartOptions.labels[index] = {
                        'name': this.$coins[e[0]].persianName,
                        'value': e[1].credit || 0
                    }

                    this.series[index] = e[1].wholePriceOfCredit || e[1].totalBalance || 0
                    index++
                })
                ++this.change
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.chart {
    display: flex;
    max-height: 160px;
    max-width: 160px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
}
</style>
