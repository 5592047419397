<template>
  <div class="chart-section">
    <div class="header">
        <div class="coin-details">
            <div class="coin-title">
              {{coin.title}}
              {{coin.symbol}}
            </div>
            <div class="total-profit-section">
                سود کلی
                <div class="total-profit-percent">{{coin.change}}%<inline-svg :class="{'negative-arrow':totalProfitPercent<0}" :src="require('../../../assets/icons/value-arrow.svg')" /></div>
                <div>{{coin.price.toLocaleString()}}</div>
            </div>
        </div>
        <div class="coin-percent-chart">
            <CoinChartPercent :percent="60"/>
        </div>

    </div>
   <div class="chart">
       <apexchart type="line" width="226" :options="chartOptions" :series="series"></apexchart>
    </div>
    
    <div class="footer">
        <div class="label-value">
          <span class="legend"><div class="legend-circle"><span class="yellow-legend"/></div>قیمت خرید</span>
          <span class="date">{{date}}</span>
        </div>
        <div class="coin-intrade-value">
          <div class="value">{{coin.amount}}</div>
           <div class="portfoy-bg " ><div class="portfoy-fill" :style="{width:60+'%'}"/></div>
        </div>
    </div>
    <div class="label-value profit-value">
      <span class="legend"><div class="legend-circle"><span class="green-legend"/></div>میزان سود</span>
      <span class="value">{{coin.changeT}}</span>
    </div>
    <button class="section-btn">مشاهده بیشتر</button>

   
  </div>
</template>

<script>
import CoinChartPercent from './CoinPercentChart'
export default {
  name: 'ChartSection',
  components:{
    CoinChartPercent
  },
  props:{
    
  },
  data(){
    return {
      coin:{"title":"بیتکوین","symbol":"BTC","icon":"bitcoin","price":160000,"minbuy":0.514,"amount":1.12,"commission":0.02,"change":6.4,"changeT":8.4,"buyPrice":336729432,"sellPrice":336729432,"currentPrice":336729432,"buyPriceT":346,"sellPriceT":346,"currentPriceT":346,"chart":""}
,
      totalProfitPercent:2.4,

          date:'۱۵:۱۱ - ۱۳۸۶/۷۰/۲۱',

             series: [{
            name: 'Sales',
            data: [4, 3,5, 13, 9, 17, 2, 15, 12, 7, 19, 5, 13, 9, 17, 2, 7, 5]
          },{
            name: 'Sales1',
            data: [1, 3, 5, 17, 20, 19, 22, 9, 11, 7, 19,  10, 9, 29, 19, 22, 9 ,5]
          }],
          chartOptions: {
            chart: {
              height: 90,
              type: 'line',
                sparkline: {
                    enabled: true,
                }
            },
                stroke: {
                width: 2,
                curve: 'smooth'
            },
          
             colors: ['#80B73B','#FFCA2E',],
        
   
          },
          

    }
  }
 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.value{
  font-size: clamp(11px,1.1vw,12px);
}
.date{
  font-size: clamp(12px,1.1vw,13px);
}

.profit-value{
  margin-top: 16px;
}

.total-profit-section{
  display: flex;
  align-items: center;
  column-gap: 4px;
  font-size: clamp(13px,1.3vw,14px);
}
.coin-percent-chart{
  flex-grow: 1;
  max-width: 66px;
}
.section-btn{
    background: #FFCA2E;
    border-radius: 50px;
    padding: 6px 0px;
    width: 100%;
    margin-top: 20px;
}
.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.portfoy-bg{
    position: relative;
    max-width: 170px;
    width: 100%;
    display: flex;
    align-items:center;
    height: 3px;
    border-radius: 2px;
    background: #D4D1FF;
}

.negative-arrow{
    transform: rotate(180deg);
}
.coin-title{
  font-size: clamp(14px,1.6vw,16px);
}
.total-profit-value{
  font-weight: bold;

 
}

.total-profit-percent{
    color: var(--dashboard-totalProfit-percent);
    stroke: var(--dashboard-totalProfit-percent);
    font-size: clamp(11px,1.1vw,12px);
    font-weight: bold;
    display: flex;
    column-gap: 4px;
    align-items: center;
}
.portfoy-fill{
    position: absolute;
    left: 0px;
    background: #FFBF00;
  
    height: 4.5px;
    border-radius: 2px;

}
.footer{
  display: flex;
  flex-direction: column;
  row-gap:8px;
}
.coin-intrade-value{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chart-section{
   background: var(--dashboard-section-bg);
  padding: 16px;
  color: var(--wallet-chart-section-text);
  flex-grow: 1;
  border-radius: 20px;
}
.legend{
  display: flex;
  align-items: center;
  column-gap: 4px;
  font-size: clamp(12px,1.2vw,13px);
}
.label-value{
  display: flex;
  justify-content: space-between;
}

.legend-circle{
  height:13px;
  width: 13px;
  background: #F8F8FF;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.green-legend{
  border-radius: 50%;
  background: #80B73B;
  width: 5px;
  height: 5px;
}
.yellow-legend{
  border-radius: 50%;
  background: #FFBF00;
  width: 5px;
  height: 5px;
}
</style>
