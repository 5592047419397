<template>
<div class=" table-section">
    <div class="sell-table">
        <div class=" grid-row ">
            <div class="light-font">قیمت واحد({{$coins[tradeTo].persianName}})</div>
            <div class="light-font">مقدار({{$coins[tradeFrom].code}})</div>
            <div class="light-font resp">قیمت کل({{$coins[tradeTo].persianName}})</div>
        </div>
        <div class=" grid-row " v-for="(order,index) in tableContentsSell" :key="index">
            <div class="light-font">{{$toLocal(order.unitPrice,0)}}</div>
            <div class="light-font">{{$toLocal(order.volume,$decimal[tradeFrom])}}</div>
            <div class="light-font resp">{{$toLocal(order.wholePrice,0)}}</div>
            <div class="grad-bg red-bg" :style="{width:order.executedPercent+'%'}" />
        </div>

    </div>
    <div class="buy-table">
        <div class=" grid-row " v-for="(order,index) in tableContentsBuy" :key="index">
            <div class="light-font">{{$toLocal(order.unitPrice,0)}}</div>
            <div class="light-font">{{$toLocal(order.volume,$decimal[tradeFrom])}}</div>
            <div class="light-font resp">{{$toLocal(order.wholePrice,0)}}</div>
            <div class="grad-bg green-bg" :style="{width:order.executedPercent+'%'}" />
        </div>

    </div>

</div>
</template>

<script>
import {
    tradeTo,
    tradeFrom,
} from "@/library/reuseableFunction";
export default {
    name: 'OrderBookTable',
    props: ['tableContentsBuy', 'tableContentsSell'],
    computed: {
        tradeTo,
        tradeFrom
    },
    data() {
        return {
            choice: this.default || '',
            dropOpen: false,
        }
    },
    // methods: {
    //     filterOrder(tradeType) {
    //         return this.orders.filter(order => {
    //             if (this.$coinUnit[order.coinName] == this.coin.symbol &&
    //                 tradeType == order.tradeType)
    //                 return order
    //         })
    //     }
    // }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.dropdown {
    padding: 0px 16px;
    border-radius: 20px;

}

.items {
    height: 0px;
    transition: height 0.8s;
    overflow: hidden;
}

.field {
    height: 39px;
    font-size: clamp(13px, 1.4vw, 14px);
}

.icon {
    stroke: var(--dropdown-icon)
}

.grad-bg {
    height: 20px;
    position: absolute;
    z-index: 0;
    left: 0px;
    opacity: 0.2;
    border-radius: 20px;
}

.red-bg {
    background: #DD1B60;
}

.green-bg {
    background: #80B73B;
}


.grid-row {
    display: grid;
    position: relative;
    height: 20px;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
    color: var(--P2P-table-row-color);
    font-size: clamp(12px, 1.3vw, 13px);
    font-family: 'YekanLight';
    margin-top: 4px;

}

.grid-row>div:first-child {
    text-align: right;
    z-index: 1;
}

.grid-row>div:nth-child(3) {
    text-align: left;
    z-index: 1;

}

.grid-row>div:nth-child(2) {
    text-align: center;
    z-index: 1;

}

.table-section {
    background: var(--P2P-table-bg);
    padding: 16px;
    border-radius: 15px;
    flex-direction: column;
    display: flex;
    flex-grow: 2;
    flex-basis: 250px;
    min-width: 230px;

}

.table-section > div {
    flex: 1;
}

@media(max-width:600px) {
    .resp {
        display: none;
    }
}
</style>
