<template>
  <div class="history">
       <div class="page-header">
           <div class="table-title">{{currentTable.label}}</div>
           <div class="table-filter">
             <DropFilter class="sf"  @choose="value=>currentTable=value" :items="pages"/>
           </div>
       </div>
      
       <Logins v-if="currentTable.value=='logins'"/>
       <Transactions v-if="currentTable.value=='transactions'"/>
       <Orders v-if="currentTable.value=='orders'"/>
       <Trades v-if="currentTable.value=='trades'"/>
  </div>
</template>

<script>
import DropFilter from './DropFilter'
import Logins from './Logins'
import Transactions from './Transactions'
import Orders from './Orders'
import Trades from './Trades'
export default {
  name: 'History',
  props: {
   
  },
  data(){
    return{
        pages:[
            {label:'تاریخچه ورود به حساب کاربری',value:'logins'},
            {label:'واریز و برداشت',value:'transactions'},
            {label:'سفارشات',value:'orders'},
            {label:'معاملات',value:'trades'},

        ],
        currentTable: {label:'تاریخچه ورود به حساب کاربری',value:'logins'},

        
    }
  },
  components:{
      Logins,
      DropFilter,
      Transactions,
      Orders,
      Trades
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.table-container{
    max-height: 500px;
    overflow-y: scroll;
    width: 100%;
    margin-top: 20px;
}

.sf{
    z-index: 23!important;
}
.page-header{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
    justify-content: space-between;
    color: var(--history-page-header)!important;
}
 ::v-deep  .table-filter{
    max-width: 220px;
    flex-grow: 1;
    color: var(--history-page-header) !important;

}

::v-deep .filters{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    row-gap: 10px;

    flex-wrap: wrap;
}

::v-deep .left-filter{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    row-gap: 10px;

    column-gap: 8px;
    
}
::v-deep .right-filter{
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    align-items: center;
    column-gap: 8px;
    
}
.history{
    background: var(--history-bg);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    padding: 16px;
}
::v-deep .coin-title{
    display: flex;
    align-items: center;
    column-gap: 2px;
    font-family: 'YekanBold';
    color: var(--table-coin-title);
}
::v-deep .coin-icon{
    width: 16px;
    margin-left: 4px;
    margin-bottom: 3px;
}

::v-deep .tradeType{
    color: var(--history-blue);
}

::v-deep table{
    width: 100%;
}
::v-deep .table-header{
    position: sticky;
    top: 0px;
    z-index: 2;
    background: var(--history-bg);
    color: var(--table-header);
    font-size: clamp(12px,1.3vw,14px);
    font-family: 'YekanLight';
}

::v-deep .tag{
    font-size:clamp(11px,1.1vw,13px);
    font-family: 'YekanLight';
}
::v-deep .table-row{
    font-size: 12px;
    font-family: 'YekanLight';
    color: var(--table-row);
   
}
::v-deep td{
        padding: 10px;
}

::v-deep .withdraw-sell{
    color: var(--table-red);
    
}
::v-deep .depsoit-buy{
    color: var(--table-green);
}
::v-deep .status{
    display: flex;
    align-items: center;
    column-gap: 6px;
}




 @media(max-width:1200px){
   
    .resp-1{
        display: none;
    } 
}
@media(max-width:900px){
    .resp-2{
        display: none;
    } 
}

@media(max-width:600px){
    .resp-3{
        display: none;
    }
}

</style>
