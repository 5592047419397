<template>
<ModalCard @close="$emit('modalToggle','')">
    <template #title>
        تغییر رمز عبور
    </template>
    <template #body>
        <div class="text">برای تغییر رمز عبور، ابتدا رمز عبور فعلی خود را وارد کنید.</div>
        <LabelInput label="رمز عبور فعلی" @input="value=>oldPassword=value" type='password'/>
        <p class="error" v-if="passError">*{{passError}}</p>
        <LabelInput label="رمز عبور" @input="value=>newPassword1=value" type='password' />
        <p class="error" v-if="pass1Error">*{{pass1Error}}</p>
        <LabelInput label="تکرار رمز عبور" @input="value=>newPassword2=value" type='password' />
        <p class="error" v-if="pass2Error">*{{pass2Error}}</p> 
        <button class="modal-submit-btn" :class="{disabled:!oldPassword|| !newPassword1||!newPassword2}"
           :disabled="!oldPassword || !newPassword1||!newPassword2" @click="submit()">تایید </button>
    </template>

</ModalCard>
</template>

<script>
import ModalCard from './ModalCard'
export default {
    name: 'ChangePass',
    components: {
        ModalCard,
    },
    data() {
        return {

            newPassword1: '',
            oldPassword: '',
            newPassword2: '',
            passError: '',
            pass1Error: '',
            pass2Error: '',

        }
    },
    methods: {
        async submit() {

            let passSent = {
                oldPassword: this.oldPassword,
                password: this.newPassword1
            }
            await this.check2Pass()
            if (this.newPassword1 && this.newPassword2) {
                if (this.checkPass())
                    this.state.loading = true
                try {
                    console.log(passSent)
                    const res = await this.$axios.post(`/users/change-password`, passSent)
                    if (res.message === "password changed successfully") {
                        this.$error('رمز شما تغییر یافت', '', 'success')
                        this.oldPassword = ''
                        this.newPassword1 = ''
                        this.newPassword2 = ''
                        this.$emit('modalToggle','')
                    }
                } catch (error) {
                    if (error.response.data.message === 'Old Password Is Incorrect') {
                        this.passError = 'رمز قبلی اشتباه است.'
                    }
                }
            }
        },
        checkPass() {
            if (!this.oldPassword) {
                this.passError = 'رمز فعلی اجباری';
            } else {
                this.passError = ''
            }
            if (this.newPassword1) {
                if (this.newPassword1.length < 8) {
                    this.pass1Error = 'حداقل 8 کاراکتر';
                } else if (!this.validPass(this.newPassword1)) {
                    this.pass1Error = 'ترکیبی از حروف کوچک و بزرگ و عدد';
                } else if (!this.newPassword2) {
                    this.pass1Error = ''
                    this.pass2Error = 'تکرار رمز اجباری';
                } else if (this.newPassword2 !== this.newPassword1) {
                    this.pass1Error = ''
                    this.pass2Error = 'تکرار رمز مطابقت ندارد';
                } else {
                    this.pass2Error = ''
                }
            }
            if (!this.passError.length && !this.pass1Error.length && !this.pass2Error.length) {
                return true;
            }
        },
        async check2Pass() {
            if (!this.$validPass(this.oldPassword) || this.oldPassword.length < 8) {
                this.$error('اطلاعات وارد شده اشتباه است', 'رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد')
                console.log(this.oldPassword)
                return Promise.reject('wrong pass : ' + this.oldPassword)
            }
            if (this.newPassword1 !== this.newPassword2) {
                this.$error('اطلاعات وارد شده اشتباه است', 'تکرار رمز اشتباه است')
                return Promise.reject('repeat failed : ' + this.newPassword1 + '!==' + this.newPassword2)
            }
        },
        validPass(password) {
            const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
            return re.test(password);
        }

    },
}
</script>

<style scoped>
.text {
    font-size: 12px;
    color: var(--modal-text)
}
.error{
  font-size: clamp(12px,1.3vw,14px);
  color: red;
}
.disabled{
  opacity: 0.7;
}
</style>
