<template>
<ModalCard @close="$emit('modalToggle','')">
    <template #title>
        افزودن تیکت
    </template>
    <template #body>
        <DropDown @choose="value=>data.type=value" :defaultValue='ticket.type' :items="[{label:'فنی',value:'TECHNICAL'},{label:'عمومی',value:'GENERAL'},]" :disabled="ticket.type" />
        <LabelInput @input="(value)=>data.subject=value" :value='ticket.subject' placeholder="عنوان مورد نظر خود را وارد کنید" :disabled="ticket.subject" />
        <textarea :rows="5" class="text-area" v-model="data.text" />
        <button :disabled=" (!data.text || !data.subject) " :class="{'modal-submit-btn':true,disabled:(!data.text || !data.subject)}" @click="submit()">تایید </button>
     </template> 

  </ModalCard>
    
</template>

<script>
import ModalCard from './ModalCard'
import DropDown from '../tools/DropDown'
export default {
    name: 'AddTicket',
    components: {
        ModalCard,
        DropDown
    },

    props: {
        ticket:{
          type:Object,
          default:()=>{return {type:null,subject:null}}
        }
    },
   
    methods: {
        submit() {
            let formData = new FormData
            if (this.ticket.type)
                formData.append('text', this.data.text)
            else
                for (let field in this.data) {
                    formData.append(field, this.data[field])
                }
            console.log(formData)
            this.$emit('addTicket', formData)
            this.$emit('modalToggle','')

        },


    },
    data() {
        return {
         
            data: {
                type: 'GENERAL',
                subject:this.ticket.subject,
                text:'',
            },
            types: [{
                    label: 'فنی',
                    value: 'TECHNICAL'
                },
                {
                    label: 'عمومی',
                    value: 'GENERAL'
                }
            ]

        }
    }
}
</script>

<style scoped>
.disabled {
    opacity: 0.6;
}

.text-area {
    background: var(--textarea-bg);
    border-radius: 20px;
    color: var(--label-input-text);
    outline: none;
    border: 1px solid var(--label-input-border);

}
</style>
