<template>
<AuthCard @close="$emit('authModalToggle','')">
    <template #title> ایجاد حساب کاربری</template>
    <template #body>
        <LabelInput @input="value=>{data.email=value;emailError=''}" :errorText="emailError" label="آدرس ایمیل" />
        <LabelInput @input="value=>data.password=value" type='password' label="رمز عبور" />
        <LabelInput @input="value=>data.referralCode=value" label="کد دعوت(اختیاری)" />
        <div class="terms"><input type="checkbox" @click="check=!check">
            <div class="terms-text">با ثبت نام در 700 شرایط و قوانین استفاده از سرویس‌های سایت را می‌پذیرید.</div>
        </div>

        <div class="captcha-container">
            <img src='../../assets/public/captcha-code.png' />
        </div>
        <LabelInput @input="value=>data.securityCode=value" type='securityCode' label="کد امنیتی" />

        <div class="btns">
            <button :class="{'auth-submit-btn':true,disabledbtn:disable  }" 
            :disabled="disable" @click="send()">تکمیل ثبت نام</button>
            <button class="google-btn">
                ادامه با حساب گوگل
            </button>
        </div>
        <div class="suggest">حساب کاربری دارید؟<a @click="$emit('authModalToggle','Login')">وارد شوید</a></div>
     
    </template>


</AuthCard>
</template>

<script>
import AuthCard from './AuthCard'
  
export default {
    name: 'Login',
    components: {
        AuthCard,
    },
    data() {
        return {
             recaptcha: false,
            check: false,
            data: {
                email: '',
                password: '',
                referralCode: '',
                deviceType: '',
                osType: '',
                browser: '',
                securityCode:''
            },
            emailError: '',
            passwordError: ''
        }
    },
     computed: {
        disable() {
            return !this.data.email || !this.data.password || !this.data.securityCode || this.emailError.length > 0 || this.passwordError.length > 0 || !this.check
        },
        readOnly() {
            return localStorage.referralCode || this.$route.query.ref
        }
    },
    methods: {

        googleSso() {
            window.location.href = this.$google_sso
        },
        async send() {
            try {
                await this.checkEmail()
                await this.check2Pass()
                this.state.loading = true
                const res = await this.$axios.post('/users/register', this.data)
                if (res.message === 'user created successfully , verification email sent') {
                    if (res.baseDTO.towStepAuthenticationType === 'EMAIL') {
                        this.$cookies.set('username', this.data.email)
                        this.state.userInfo.email = this.data.email
                        this.state.userInfo.towStepAuthenticationType='Email'
                        this.$emit('authModalToggle','')
                    }
                } else if (res.token) {
                    this.$setToken(res.token, this.state.userInfo.remember)
                    await this.$router.push('/Panel/Profile')
                    this.$emit('authModalToggle','')

                }
            } catch (e) {
                console.log(e)
            }
        },
        check2Pass() {
            if (!this.$validPass(this.data.password) || this.data.password.length < 8) {
                this.passwordError = 'رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد'
                this.$error('اطلاعات وارد شده اشتباه است', 'رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد')
                return Promise.reject('wrong pass : ' + this.data.password)
            } else {
                this.passwordError = ''
            }
        },
        checkEmail() {
            if (!this.$validEmail(this.data.email) || !this.data.email) {
                this.emailError = 'ایمیل صحیح وارد نمایید'
                this.$error('اطلاعات وارد شده اشتباه است', 'ایمیل صحیح وارد نمایید')
                return Promise.reject('repeat failed: ' + this.data.email)
            } else {
                this.emailError = ''
            }
        },

        getData() {
            this.data.osType = this.$detectOs()
            this.data.browser = this.$detectBrowser() || 'CHROME'
            this.data.deviceType = this.$detectDevice()
        },

    },
    mounted() {
        this.getData()
        if (this.$route.query.ref || localStorage.referralCode) {
            localStorage.referralCode = this.$route.query.ref || localStorage.referralCode
            this.data.referralCode = this.$route.query.ref || localStorage.referralCode
        }
    }
}
</script>

<style lang="scss" scoped>
.forget-pass {
    // display: flex;
    // flex-wrap: wrap;
    margin-top: 8px;
    font-size: clamp(10px, 1.2vw, 12px);
    color: var(--forget-pass-link);
    text-align: left;
    font-weight: 300;

}

.captcha-container {
    display: flex;
    justify-content: center;
}

.btns {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.suggest {
    display: flex;
    justify-content: center;
    font-size: clamp(10px, 1.1vw, 12px);
    color: var(--suggest)
}

.terms {
    display: flex;
}

.terms-text {
    color: var(--authcard-terms);
    font-size: clamp(10px, 1.1vw, 12px);

}

.suggest>a {
    color: var(--suggest-link)
}

.disabledbtn {
    opacity: 0.6;
}
</style>
