<template>
<div class="markets">
    <div class="head-part">
        <div class="head-text">
            <div class="page-title">بازار </div>
            <p>
                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.
            </p>
            <div class="search">
                <SearchBox @search="value=>regex=value" label="جستجو" />
            </div>
        </div>
        <div class="img-container ">
            <img src="../../assets/public/marketsImg.png" class="img" />
        </div>
    </div>

    <div class="markets-table">
        <div class="switch-comp">
            <SwitchComponent @choose="(choice)=>{toman_tether=choice}" :items="[{label:'تومان',value:'TOMAN'},{label:'تتر',value:'TETHER'}]" />
        </div>
        <table>
            <tr class="head">
                <!-- <ToggleComp  @choose="(choice)=>{toman_tether=choice}" :items="[{label:'تومان',value:'TOMAN'},{label:'تتر',value:'TETHER'}]"/> -->
                <td class="frst-head" align="right"> ارزها </td>
                <td>قیمت جهانی</td>
                <td>تغییرات </td>
                <td class="resp-3">قیمت خرید</td>
                <td class="resp-2">قیمت فروش </td>
                <td class="resp-1">نمودار</td>
                <td class="resp-1">عملیات</td>
            </tr>
            <tr class="row" v-for="(crypto,index) in filteredCryptos" :key="index">
                <td>
                    <div class="title">
                        <img class="icon resp-1" :src="require('../../../public/coins/'+crypto.relatedCoin+'.png')" />
                        {{$coinLabel[crypto.relatedCoin]}}
                    </div>
                </td>
                <td class="price">{{$toLocal(crypto.lastPrice,toman_tether=='TOMAN'?0:2)}}</td>
                <td>
                    <span class="change" :class="{red:crypto.last24HourChange<0,green:crypto.last24HourChange>0}">
                        <span v-if="crypto.last24HourChange>0">+</span>
                        <span v-if="crypto.last24HourChange<0">-</span>
                        {{Math.abs(crypto.last24HourChange).toFixed(2)}} %
                    </span>
                </td>
                <td class="resp-3 price"><span>{{$toLocal(crypto.sellPrice,toman_tether=='TOMAN'?0:2)}}</span></td>
                <td class="resp-2 price"><span>{{$toLocal(crypto.buyPrice,toman_tether=='TOMAN'?0:2)}}</span></td>
                <td class="resp-1 ">

                    <CoinDataChart :data="crypto.lastWeekData" />

                </td>
                <td class="resp-1 "><a class="action" :href="'Panel/P2P/TOMAN/'+$coin2Snake[crypto.relatedCoin]"> معامله حرفه ای</a></td>
                <td class="resp-1 "><a class="action" :href="'Panel/P2P/TOMAN/'+$coin2Snake[crypto.relatedCoin]"> معامله سریع </a></td>
            </tr>
        </table>
        <!-- <div class="link-btn-container">
            <button class="link-btn">مشاهده همه</button>
        </div> -->
    </div>

</div>
</template>

<script>
import CoinDataChart from './HomePage/CoinDataChart'
import SwitchComponent from '../tools/SwitchComponent.vue'
import SearchBox from '../tools/SearchBox'
import {
    Loop
} from "@/library/reuseableFunction";
export default {
    name: 'HelloWorld',
    components: {
        SwitchComponent,
        SearchBox,
        CoinDataChart
    },
    data() {
        return {
            regex:'',
            cryptos: [],
            cryptoName: "",
            toman_tether: "TOMAN",
            loading: false,
            alive: false,

        }
    },
    computed:{
            
          filteredCryptos() {
            return this.cryptos.filter(item => 
               ( this.$coinLabel[item.relatedCoin].toLowerCase().includes(this.regex.toLowerCase()) ||
                    item.relatedCoin.toLowerCase().includes(this.regex.toLowerCase()) ||
                    this.$coin2Snake[item.relatedCoin].toLowerCase().includes(this.regex.toLowerCase())) 
                    && item.marketType.includes(this.toman_tether) )

        },

        },
    
    methods: {

        async getMarkets() {
            let res = await this.$axios.get('/trades/market-info', {
                params: {
                    weeklyDataIncluded: true
                }
            }).then(response => res = response.content)
            if (res) {
                this.cryptos = res
            }
        },
      
    },
    mounted() {
        
        if (this.state.loop) {
            this.loop1 = new Loop(this.getMarkets, 5000, 'exchangeOrder')
            this.loop1.start()
        } else {
            this.getMarkets()
        }

    },
    beforeDestroy() {
        this.loop1.stop()
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.img-container>img {
    width: 100%;
    max-width: 690px;
}

.img-container {

    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    min-width: 200px;
}

.head-part {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
}

.page-title {
    color: var(--contactus-page-title);
    font-size: clamp(30px, 4vw, 48px);
}

.head-text {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-size: clamp(14px, 1.4vw, 16px);
    flex-basis: 300px;
    color: var(--contactus-head-text);
    row-gap: 24px;
}

.search {
    max-width: 410px;
}

.markets {
    width: 85%;
    margin-bottom: 100px;
}

.table-header {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    width: 100%;
}

.change {
    display: flex;
    justify-content: flex-end;
    direction: ltr;
    color: var(--publicpage-markets-change-action)
}

.link-btn {
    padding: 8px 32px;
    box-shadow: -8px -8px 16px rgba(38, 32, 111, 0.1), 8px 8px 16px rgba(2, 0, 23, 0.1), inset -8px -8px 16px rgba(48, 79, 221, 0.2), inset 8px 8px 16px rgba(48, 79, 221, 0.4);
    border-radius: 50px;
    margin-top: 16px;
    font-size: 14px;
    background: var(--publicpage-markets-link-btn-bg);
    color: var(--publicpage-markets-link-btn-text);
    border: 0.3px solid var(--publicpage-markets-link-btn-border);

}

.link-btn-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.action {
    color: var(--publicpage-markets-change-action);
    font-weight: 300;
    ;

}

.chart-container {
    display: flex;
    /* width: 100px; */

    align-items: center;
}

.markets-table {
    padding-top: clamp(80px, 12vw, 130px);
    background: var(--publicpage-markets-bg);
    padding: clamp(12px, 1.3vw, 16px) clamp(30px, 3.5vw, 48px);
    border-radius: 20px;
    width: 100%;
}

.chart {
    vertical-align: inherit;
    max-height: 60px;
}

.switch-comp {
    max-width: 124px;
    margin-right: -20px;
}

.title {
    color: var(--publicpage-markets-title);
    font-size: 14px;
    /* display: flex; */
    display: flex;
    align-items: center;
    font-weight: bold;
}

.title-basecoin {
    font-size: 12px;
    font-weight: 300;
}

.markets-link {
    text-align: center;
    margin-top: 16px;
}

.icon {
    margin-left: 8px;
    width: 30px;
}

.head {
    background: transparent;
    color: var(--publicpage-markets-header);
    height: 41px;
    ;
    font-size: clamp(13px, 1.3vw, 14px);
    font-weight: 400px !important;
}

.row {
    height: 64px;
    font-size: clamp(12px, 1.3vw, 14px);

}

td {
    font-weight: 400px !important;
}

.price {
    color: var(--publicpage-markets-price);
}

table {
    margin-top: 10px;
    border-collapse: collapse;
    width: 100%;
}

@media(max-width:900px) {
    .resp-1 {
        display: none;
    }
}

@media(max-width:550px) {
    .resp-2 {
        display: none;
    }
}

@media(max-width:400px) {
    .resp-3 {
        display: none;
    }
}
</style>
