<template>
  <div class="level-page">
      <div class="user">
          <inline-svg class='user-icon' :src="require('../../assets/icons/Profile.svg')"/>
          <div :class="{'link-title':true,'user-info':true}">
              <!-- 'hidden':!is_open -->
              <div class="user-name">{{state.userInfo.firstName+' '+state.userInfo.lastName}}</div>
              <div class="user-level">سطح {{userLevels[state.userInfo.userAccountLevel].label}} </div>
          </div>
      </div>
      <div class="guide">با افزایش حجم معاملات خود در سه ماه اخیر میتوانید سطح کاربری خود را افزایش دهید.</div>
     <div class="bg-container">
        <div class="animation-container">
            <img class="bg-img" src="../../assets/public/levels-img.png">
            <!-- <LottieAnimation class="animation" path="https://assets1.lottiefiles.com/packages/lf20_f7yvir0x.json"  /> -->
   
        </div>
     </div>
      <div class="levels" :style="{backgroundImage:`linear-gradient( to left, var(--level-blue) 0%, 
       var(--level-blue) ${levelPercent}%, #FFFFFF ${levelPercent}%)`}">
        <div v-for="i in 5" :style="{right:(i-1)*25+'%'}" :key="i" class="outer-circle">
            <div  class="circle"/>
        </div>

      </div>
        <div class="level-stage-container" :style="{'right':levelPercent+'%'}">
            <div class="level-stage-pointer"/>
            <div class="level-stage-box">
                <img  class="warning-icon" src="../../assets/icons/warning.svg"/>
                <div>
                   سطح {{userLevels[state.userInfo.userAccountLevel].label}}   

                </div>
                </div>
        </div>

      <button class="ticket-btn">ارسال تیکت</button>
    
   
  </div>
</template>

<script>
export default {
  name: 'Level',
  components:{
  },
  props: {
   
  },
  computed:{
      levelPercent(){
          return this.userLevels[this.state.userInfo.userAccountLevel].value*20
      }
  },
  data(){
    return{
       
           userLevels: {
                'BRONZE': {label:'اول',value:1},
                'SILVER': {label:'دوم',value:2},
                'GOLD': {label:'سوم',value:3},
                'PLATINUM': {label:'چهارم',value:4},
                'SUPERVISOR': {label:'پنجم',value:5},
            },
    
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.animation-container{
    max-width: 600px;
    width: 100%;
    position: relative;
    z-index: 0;
   
}
.bg-container{
 display: flex;
align-items: center;
justify-content: center;
}
.animation{
    position: absolute;
    top:-30%;
    max-width: 440px;
    width: 80%;
    z-index: -2;

}
.bg-img{
    width: 100%;
    height: auto;
   
}
.user-icon{
    stroke: var(--level-blue);
    width: 18px;
    height: 24px;
}

.user-name{
    color: var(--level-blue);
    font-weight: bold;
    font-size: clamp(16px,1.7vw,18px);

}
.user-level{
    color: var(--level-user-level);
    font-weight: bold;
    font-size: clamp(11px,1.2vw,12px);
}
.user{
    display: flex;
    align-items: center;
    column-gap: 15px;
}

.guide{
    color: var(--level-page-guide-text);
    font-size: clamp(12px,1.3vw,14px);
    font-family: "YekanLight";
}
.level-page{
    display: flex;
    flex-direction: column;
    background: var(--level-page-bg);
    border-radius: 20px;
    padding: 16px;
    flex-grow: 1;
}

.circle{
    height: 11px;
    width: 11px;
    border-radius: 30px;
    background: var(--level-blue);

}
.outer-circle{
    height: 18px;
    width: 18px;
    border-radius: 30px;
    background: var(--level-circle-border);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-right: -10px;
}

.levels{
    display: flex;
    margin-top: 20px;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
    max-height: 5px;
    position: relative;
    border-radius: 4px;
    background: var(--level-line-bg);
}
.level-stage-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 248px;
    width: 30%;
    position: relative;
}

.level-stage-box{
    background: #FF2D78;
    
    border-radius: 50px;
    font-size: clamp(10px,1.3vw,13px);
    padding: 8px;
    width:100%;
    margin-right: -100%;
    display: flex;
    justify-content: center;
    margin-top: -1px;
    column-gap: 8px;
    text-align: center;
    align-items: center;
    color: #F8F8FF;

}
.ticket-btn{
    margin-top: 16px;
    background: #021877;
    border-radius: 50px;
    padding:8px 0px ;
    width: 100%;
    max-width: 253px;
    color: #ECF0FF;
    margin-right: auto;
    margin-left: auto;
    
}
.level-stage-pointer{
    clip-path: polygon(50% 60%, 0 100%, 100% 100%);
    background: #FF2D78;
    margin-right: -100%;
    height: 16px;
    width: 20px;
}

@media(max-width:600px){
    
.warning-icon{
    display: none;   
}
}
</style>
