<template>
<div class="carousel">
    <vueper-slides
    :breakpoints="breakpoints"
    class="no-shadow"
    :visible-slides="4"
    :arrows="false"
    :bullets="false"
    :rtl="true"
    :gap="3"
    :autoplay="true"
    :duration="2000"
      :slide-ratio="1 / 6"
      fixedHeight='351px'
    >
    <vueper-slide  v-for="(crypto,index) in cryptos" :key="index" :title="index.toString()">
      <template #content>
      <div class="vueperslide__content-wrapper" >
      
       <div class="post">
     
      <div class="post-img">
          <img  src='../../../assets/public/postImg.png'/>
      </div>
      <div class="post-content">
          <div class="post-header">
               <div class="post-title">عنوان مطلب</div>
               <div class="post-score"><inline-svg :src="require('../../../assets/icons/star.svg')"/>20 </div>
          </div>
          <div class="post-preview">
              لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، .

               </div>
<br>
          <div class="post-footer"> 
            <div class="post-date"><img src='../../../assets/icons/clock.svg'/>  1 &nbsp; هفته &nbsp; قبل </div>
            <div class="author"><span class="author-label">نویسنده</span> &nbsp; &nbsp; <span class="author-name">امیر جلالی</span></div>
          </div>
      </div>
  </div>
   

    
      </div>
    </template>
    </vueper-slide>
    </vueper-slides>

</div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import variables from '../../../library/variables.json'
export default {
  name: 'CryptoCarousel',
  props: {
   
  },
  data(){
    return{
          cryptos:variables.cryptos,
          breakpoints: {
                 
                1200: {
                  visibleSlides:3 
                },
                900: {
                  visibleSlides: 2
                },
                600: {
                  visibleSlides: 1,
                 
                },
               
              
                // The order you list breakpoints does not matter, Vueper Slides will sort them for you.
               
              },

    }
  },
   components: { VueperSlides, VueperSlide },
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.vueperslide__content-wrapper{
   background: var(--crypto-carousel-bg);
   border-radius: 20px;
}
.post{

    height: 100%;
   display: flex;
   width: 100%;
   flex-direction: column;
   align-items: center;
   align-content: flex-start;
}

.carousel{
//   height: 351px;
  width: 100%;margin-top:80px;
  border-radius: 20px;
  overflow: hidden;
}
.vueperslide__title{
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}
.post-img{
    width: 100%;
}

.post-img > img{
    width: 100%;
    height: auto;
}
.post-preview{
    font-size: clamp(12px,1.3vw,13px);
    margin-top: 8px;
    color:var(--publicpage-post-preview);
    text-align: right;
    opacity: 0.7;
}

.post-footer{
    margin-top: -8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.author{
    display: flex;
    font-size: 12px;
}

.author-name{
    font-weight: bold;
    color:var(--publicpage-post-author-name)
}
.author-label{
    color:var(--publicpage-post-author-label)
}

.post-date{
    display: flex;
    align-items: center;
    column-gap: 5px;
    color:var(--publicpage-post-date);
    font-size:12px;
}

.post-title{
    font-size: clamp(14px,1.6vw,16px);
    color:var(--publicpage-post-title);
    font-weight: bold;
}
.post-content{
    padding:16px 14px;
    width: 100%;
}
.post-score{
    display: flex;
    align-items: center;
    color: #FFBF00;
    stroke: #FFBF00;
}

.post-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>