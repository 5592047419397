<template>
<div class="depsoits-section">

    <div class="head">
        <div class="section-title">دارایی ها</div>
        <div class="wallets">
            <div>
                <div class="wallets-header">کیف پول ها</div>
                <div class="wallets-value">کیف پول {{marketDict[marketType]}}</div>
            </div>
            <div>
                <div class="wallets-header">ارزش کل دارایی</div>
                <div class="wallets-value">{{$toLocal(totalPriceOfCredits,0)}} تومان</div>
            </div>
            <div>
                <div class="wallets-header">دارایی کیف پول تومان</div>
                <div class="wallets-value">{{$toLocal(tomanBalance,0)}} تومان</div>
            </div>
        </div>

        <div class="tomanBtns-marketSwitch">
            <DropDown class="dropfilter" @choose="(choice)=>{marketType=choice;$emit('changeMarket',choice)}" :items="[{label:'بازار',value:'MARKET'},{label:'صرافی',value:'EXCHANGE'}]" />
            <div class="toman-btns">
                <button class="toman-withdraw" @click="modalType='TomanWithdraw'">برداشت از کیف پول تومان</button>
                <button class="toman-depsoit" @click="modalType='TomanDepsoit'">واریز به کیف پول تومان</button>
            </div>
        </div>

    </div>
    <div v-if="!coinIndex && coinIndex!=0" class="table">
        <div class="row table-header">
            <div>ارز</div>
            <div>دارایی</div>
            <div class="resp-1">ارزش فعلی</div>
            <div class="resp-2">تغییرات بازار</div>
            <div class="resp-1">قیمت خرید</div>
            <div>آخرین قیمت</div>
            <div class="resp-3">قابل برداشت</div>
            <div class="resp-3">سود شما</div>
            <div class="resp-3"> دارایی</div>
        </div>
        <div class="depsoit-details" v-for="(coin,index) in sorted" :key="index">
            <div class="row">
                <div>
                    <div class="coin-title">
                        <img class="crypto-icon resp-3" :src="require('../../../../public/coins/'+coin[0]+'.png')" />
                        &nbsp;&nbsp;
                        <div>{{$coins[coin[0]].code}}</div>
                    </div>
                </div>
                <!-- {{coin[1].buyPrice.toLocaleString()}} -->
                <div>{{$toLocal(coin[1].credit,$decimal[coin[0]])}}</div>
                <div class="resp-1">{{$toLocal(coin[1].wholePriceOfCredit,0)}}</div>
                <div class="market-change resp-2">{{$toLocal(Math.abs(coin[1].change),2)}}%
                    <inline-svg :class="{'negative-arrow':coin[1].change<0}" :src="require('../../../assets/icons/value-arrow.svg')" />
                </div>
                <div class="resp-1">{{$toLocal(coin[1].buyPrice,0)}}</div>
                <div>{{$toLocal(coin[1].price,0)}}</div>
                <div class="resp-3">{{coin[1].credit-coin[1].blockedCredit}}</div>
                <div class="profit resp-3"> {{$toLocal(Math.abs(coin[1].change),2)}}%
                    <inline-svg :class="{'negative-arrow':coin[1].change<0}" :src="require('../../../assets/icons/value-arrow.svg')" />
                </div>
                <div class="coin-chart resp-3">
                    <CoinChartPercent :percent="totalPriceOfCredits?(coin[1].wholePriceOfCredit/totalPriceOfCredits)*100:0" />
                </div>

            </div>
            <div class="transaction-btns">
                <button @click="openWithdrawModal(coin[0],coin[1].credit)">برداشت</button>
                <button @click="relatedCoin=coin[0];modalType='CryptoDepsoit'">واریز</button>
                <button @click="chooseWallet(index)">مشاهده کیف پول</button>
            </div>
            <CryptoWithdraw @modalToggle="(value)=>modalType=value" :coin="coins[index]" v-if="modalType=='CryptoWithdraw'+index" />
            <CryptoDepsoit @modalToggle="(value)=>modalType=value" :coin="coins[index]" v-if="modalType=='CryptoDepsoit'+index" />

        </div>

    </div>
    <div v-if="coinIndex || coinIndex==0" class="coin-details">
        <div class="details">
            <div class="detail-row">
                <div class="detail">
                    <span class="field-label">ارز</span>
                    <span class="field-value">
                        <img class="crypto-icon resp-3" :src="require('../../../../public/coins/'+sorted[coinIndex][0]+'.png')" />
                        &nbsp;&nbsp;&nbsp;
                        <div>{{$coins[sorted[coinIndex][0]].code}}</div>
                    </span>
                </div>
                <div class="detail">
                    <span class="field-label">دارایی</span>
                    <span class="field-value">{{$toLocal(sorted[coinIndex][1].credit,$decimal[sorted[coinIndex][0]])}}</span>
                </div>
                <div class="detail">
                    <span class="field-label">ارزش فعلی</span>
                    <span dir="ltr" class="field-value">{{$toLocal(sorted[coinIndex][1].wholePriceOfCredit,0)}}&nbsp;$</span>
                </div>
                <div class="detail">
                    <span class="field-label">سود شما</span>
                    <div class="profit resp-3"> {{$toLocal(sorted[coinIndex][1].change,2)}}%
                        <inline-svg :class="{'negative-arrow':sorted[coinIndex][1].change<0}" :src="require('../../../assets/icons/value-arrow.svg')" />
                    </div>
                </div>

            </div>
            <div class="detail-row">
                <div class="detail">
                    <span class="field-label">تغییرات بازار </span>
                    <div class="change resp-3"> {{$toLocal(sorted[coinIndex][1].change,2)}}%
                        <inline-svg :class="{'negative-arrow':sorted[coinIndex][1].change<0}" :src="require('../../../assets/icons/value-arrow.svg')" />
                    </div>

                </div>
                <div class="detail">
                    <span class="field-label">قیمت خرید </span>
                    <span dir="ltr" class="field-value">{{$toLocal(sorted[coinIndex][1].buyPrice,0)}}&nbsp;$</span>
                </div>
                <div class="detail">
                    <span class="field-label">آخرین قیمت </span>
                    <span dir="ltr" class="field-value">{{$toLocal(sorted[coinIndex][1].price,0)}}&nbsp;$</span>
                </div>
                <div class="detail">
                    <span class="field-label">قابل برداشت </span>
                    <span class="field-value">{{$toLocal(sorted[coinIndex][1].credit-sorted[coinIndex][1].blockedCredit,$decimal[sorted[coinIndex][0]])}}</span>
                </div>

            </div>
            <div class="transaction-btns">
                <button @click="openWithdrawModal(sorted[coinIndex][0],sorted[coinIndex][1].credit)">برداشت</button>
                <button @click="relatedCoin=sorted[coinIndex][0];modalType='CryptoDepsoit'">واریز</button>
                <button @click="chooseWallet(index)"> مشاهده کیف پول ها</button>
            </div>

        </div>
        <div class="coin-detail-chart">
            <CoinChartPercent :percent="totalPriceOfCredits?(sorted[coinIndex][1].wholePriceOfCredit/totalPriceOfCredits)*100:0" />
        </div>
    </div>
    <TomanWithdraw @modalToggle="(value)=>modalType=value" v-if="modalType=='TomanWithdraw'" :cash="tomanBalance" />
    <TomanDepsoit @modalToggle="(value)=>modalType=value" v-if="modalType=='TomanDepsoit'" />
    <CryptoDepsoit @modalToggle="(value)=>modalType=value" :exchange='marketType=="EXCHANGE"' :relatedCoin="relatedCoin" v-if="modalType=='CryptoDepsoit'" />
    <CryptoWithdraw @modalToggle="(value)=>modalType=value" v-if="modalType=='CRYPTOWITHDRAW'" :relatedCoin="relatedCoin" :credit="credit" :exchange='marketType=="EXCHANGE"' />

</div>
</template>

<script>
import TomanWithdraw from '../../Modals/TomanWithdraw.vue'
import TomanDepsoit from '../../Modals/TomanDepsoit.vue'
import CryptoWithdraw from '../../Modals/CryptoWithdraw'
import CryptoDepsoit from '../../Modals/CryptoDepsoit'
import * as variables from '../../../library/variables.json'
import CoinChartPercent from './CoinPercentChart'
import DropDown from '../../tools/DropDown'
export default {
    name: 'HelloWorld',
    props: ['totalPriceOfCredits', 'wallets'],
    components: {
        CoinChartPercent,
        TomanDepsoit,
        TomanWithdraw,
        CryptoWithdraw,
        CryptoDepsoit,
        DropDown

    },
    computed: {
        tomanBalance() {
            if (this.wallets['TOMAN']) {
                const temp = this.wallets['TOMAN']
                return temp.credit
            }
            return 0
        },
        sorted() {
            let entireRes = Object.entries(this.wallets)
            const sortCoins = this.$walletSortCoins
            entireRes.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
            return entireRes.filter(coin => coin[0] !== 'TOMAN')

        },

    },
    data() {
        return {
            totalValue: 34334344,
            tomanTotalValue: 74372323,
            coins: variables.cryptos,
            coinIndex: null,
            modalType: '',
            marketType: 'P2P',
            marketDict: {
                'P2P': 'بازار',
                'EXCHANGE': 'صرافی'
            }

        }
    },
    methods: {
        chooseWallet(index) {
            this.coinIndex = (this.coinIndex) ? null : index;
            this.$emit('changeCoin', this.sorted[index]);

        },
        openWithdrawModal(code, credit) {
            this.relatedCoin = code;
            this.credit = credit
            console.log(credit)
            this.modalType = 'CRYPTOWITHDRAW';
        },

        openTomanWithdrawModal() {
            this.modalType = 'TomanWithdraw';
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.dropfilter{
  z-index: 3;
    flex-grow: 1
}
.tomanBtns-marketSwitch {
    display: flex;
    column-gap: 10px;
    row-gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
}
.tomanBtns-marketSwitch > *{
    flex-grow:1
}

.toman-btns {
    flex-grow: 2;
    flex-basis: 200px;
    flex-wrap: wrap;
    display: flex;
    font-size: clamp(13px, 1vw, 14px);
    align-items: center;
    justify-content: space-between;
    column-gap: 8px;
    row-gap: 8px;
}

.toman-btns>button {
    white-space: nowrap;
    cursor: pointer;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 20px;
    border-radius: 50px;

}

.toman-withdraw {
    background: var(--toggle-comp-inactive-bg);
    color: var(--toggle-comp-inactive-text);
    box-shadow: -8px -8px 24px rgba(0, 0, 0, 0.1), 8px 8px 24px rgba(0, 0, 0, 0.1),
        inset -8px -8px 24px 1px rgba(0, 16, 85, 0.15), inset 8px 8px 24px 1px rgba(0, 93, 164, 0.1);
}

.toman-depsoit {
    background: #FFCA2E;
    /* background: white; */
    box-shadow: -8px -8px 24px rgba(255, 191, 0, 0.2), 8px 8px 24px rgba(255, 191, 0, 0.2), inset -1px -1px 16px 1px rgba(192, 144, 0, 0.5), inset 1px 1px 16px 1px rgba(192, 144, 0, 0.5);
    color: #3D3D3D;

}

.table-header {
    position: sticky;
    top: -1px;
    background: var(--dashboard-section-bg);
}

.field-label {
    font-size: clamp(11px, 1.1vw, 12px);
    font-family: 'YekanLight'
}

.field-value {
    font-size: clamp(11px, 1.1vw, 12px);
    display: flex;
    align-items: center;
}

.coin-details {
    display: flex;
    column-gap: 10px;
    margin-top: 30px;
    row-gap: 10px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.coin-detail-chart {
    display: flex;
    max-width: 100px;
    min-width: 80px;
    flex-grow: 1;
}

.detail-row {
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 25px;
    justify-content: space-between;
}

.details {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.detail {
    display: flex;
    column-gap: 8px;
    color: var(--wallet-depsoits-coin-field);
}

.row {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(9, 1fr);
    font-family: "YekanLight";
    color: var(--wallet-depsoit-row);
    font-size: 12px;
    cursor: pointer;
    width: 100%;

}

.depsoit-details {
    border-bottom: 1px solid var(--wallet-depsoit-details-border);
    padding-bottom: 8px;

}

.profit {
    display: flex;
    column-gap: 3px;
    align-items: center;
    color: var(--dashboard-depsoits-table-profit);
    stroke: var(--dashboard-depsoits-table-profit);

    font-weight: bold;
}

.change {
    display: flex;
    column-gap: 3px;
    font-size: clamp(11px, 1.1vw, 12px);
    align-items: center;
    color: var(--wallet-depsoits-coin-field);
    stroke: var(--wallet-depsoits-coin-field);

}

.market-change {
    display: flex;
    align-items: center;
    column-gap: 3px;
    stroke: var(--wallet-depsoit-row);

}

.transaction-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 8px;
    row-gap: 8px;
    margin-top: 16px;
    flex-wrap: wrap;
}

.transaction-btns>button {
    flex: 1;
    border-radius: 50px;
    padding: 7px 10px;
    border: 1px solid var(--wallet-btn-border);
    background: var(--wallet-btn-background);
    color: var(--wallet-btn-text);
    flex-basis: 80px;
    box-shadow: -8px -8px 24px rgba(0, 0, 0, 0.1), 8px 8px 24px rgba(0, 0, 0, 0.1),
        inset -8px -8px 24px 1px rgba(0, 16, 85, 0.15), inset 8px 8px 24px 1px rgba(0, 93, 164, 0.1);
}

.crypto-icon {
    width: 16px;
    height: 16px;
}

.coin-chart {
    width: 50px;
    height: 50px;
}

.coin-title {
    font-weight: bold;
    display: flex;
    align-items: center;
    column-gap: 2px;
}

.table {
    margin-top: 24px;
    max-height: 580px;
    overflow-y: auto;
}

// wallet header
.section-title {
    color: var(--dashboard-wallets-section-tite);
}

.head {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-grow: 1;
}

.wallets {
    margin-top: 15px;
    flex-grow: 1;
    justify-content: space-between;
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 50px;
}

.depsoits-section {
    background: var(--dashboard-section-bg);
    border-radius: 20px;
    flex-grow: 1;
    padding: 16px;

}

.wallets-header {
    color: var(--dashboard-wallets-header);
    font-size: 13px;
    font-family: 'YekanLight';

}

.wallets-value {
    color: var(--dashboard-wallets-value);
    font-size: 13px;
    margin-top: 6px;
}

@media(max-width:1200px) {

    .resp-1 {
        display: none;
    }

    .row {
        grid-template-columns: repeat(7, 1fr);
    }

}

@media(max-width:900px) {
    .resp-2 {
        display: none;
    }

    .row {
        grid-template-columns: repeat(6, 1fr);
    }
}

@media(max-width:600px) {
    .resp-3 {
        display: none;
    }

    .row {
        grid-template-columns: repeat(3, 1fr);
    }
}
</style>
