<template>
  <div class="advantage-sec">
      <div class="advantages">
          <div class="header">با &nbsp; 700 &nbsp; در &nbsp; هر &nbsp; کجا &nbsp; تجارت  &nbsp;کنید</div>
          <div class="sub-header">امروز &nbsp;به &nbsp;انجمن&nbsp; 700 &nbsp;بپیوندیدد</div>
          <div>
              <div class="advantage-container">
                <div class="advantage">
                    <div class="advantage-title">امنیت معاملات</div>
                    <div class="advantage-description">
                        امنیت معاملات لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است
                    </div>
                </div>
              </div>
               <div class="advantage-container">
                <div class="advantage">
                    <div class="advantage-title"> معاملات سریع</div>
                    <div class="advantage-description">
                        امنیت معاملات لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است
                    </div>
                </div>
              </div>
            <div class="advantage-container">
                <div class="advantage">
                    <div class="advantage-title">خدمات مشتریان 24/7</div>
                    <div class="advantage-description">
                        امنیت معاملات لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است
                    </div>
                </div>
              </div>
              
          </div>
      </div>
      <div class="advantage-bg">
          <img src='../../../assets/public/homepage-advantages-bg.png'/>
         <img class="moving-coin1" src='../../../assets/public/moving-coin.svg'/>
         <img class="moving-coin2" src='../../../assets/public/moving-coin.svg'/>
         <img class="moving-coin3" src='../../../assets/public/moving-coin.svg'/>

      </div>
   
  </div>
</template>

<script>
export default {
  name: 'Advantages',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">


.moving-coin1{
  position: absolute;
  animation-name: coin1;
  animation-duration: 8s;
  margin-right: 16%;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  max-width: 18%;

}

.moving-coin2{
  position: absolute;
  animation-name: coin2;
  animation-duration: 8s;
  margin-right: -11%;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  max-width: 18%;

}

.moving-coin3{
  position: absolute;
  animation-name: coin3;
  animation-duration: 8s;
  margin-right: 36%;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  max-width: 18%;

}

@keyframes coin1 {
  0% {top:0% ;transform: scale(0.6) rotate(-40deg) matrix(0.87, 0.5, -0.5, 0.87, 0, 0);}
  35% {top: -6%; transform: scale(0.6)  rotate(-40deg) matrix(0.87, -0.5, 0.5, 0.87, 0, 0);}
  70% { top: 9%; transform: scale(0.6) rotate(-40deg)  matrix(0.97, -0.26, 0.26, 0.97, 0, 0);}
  100% {top:0%;transform: scale(0.6) rotate(-40deg) matrix(0.87, 0.5, -0.5, 0.87, 0, 0);}
}



@keyframes coin2 {
  0% { top: 14%; transform: scale(0.43) rotate(-40deg)  matrix(0.97, -0.26, 0.26, 0.97, 0, 0);}
  35% {top:6% ;transform: scale(0.43) rotate(-40deg)  matrix(0.87, -0.5, 0.5, 0.87, 0, 0);}
  75% {top: 0%; transform: scale(0.43)  rotate(-40deg) matrix(0.97, 0.26, -0.26, 0.97, 0, 0);}
  100% { top: 14%; transform: scale(0.43) rotate(-40deg)  matrix(0.97, -0.26, 0.26, 0.97, 0, 0);}
}


@keyframes coin3 {
  0% { top: 51%; transform: scale(0.37) rotate(-40deg)  }
  35% {top:49% ;transform: scale(0.37) rotate(-10deg) }
  75% {top: 47%; transform: scale(0.37)  rotate(-55deg) }
  100% { top: 51%; transform: scale(0.37) rotate(-40deg)  }
}


.advantage-sec{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.sub-header{
    font-size:clamp(16px,1.7vw,18px);
    font-weight: bold;
    color: #FFBF00;

}

.header{
    font-size:clamp(21px,2.2vw,24px);
    font-weight: bold;
    color:var(--publicpage-advantages-header)

}

.advantage{
    background: var(--publicpage-advantages-bg);
    color:var(--publicpage-advantages-text);
    max-width: 200px;
    width: 100%;
    box-shadow: 0px 0px 24px rgba(66, 174, 255, 0.5);
    padding: 16px;
    border-radius: 10px;

}

.advantages{
    flex:1;
    flex-basis: 240px;

    // max-width:350px;
}
.advantage-bg{
    display: flex;
    flex: 4.5;
    flex-basis: 600px;
    position: relative;
    justify-content: center;
    // max-width: 76%;

}

.advantage-bg > img{
    width: 100%;
}

.advantage-title{
    font-size: clamp(13px,1.3vw,14px);
    font-weight: bold;
}

.advantage-description{
    font-size: clamp(12px,1.3vw,13px);
}

.advantage-container{
    display:flex;
    margin-top: 18px;
    width: 100%;
}

.advantage-container:first-child{
    justify-content: flex-start;
}
.advantage-container:nth-child(2){
    justify-content: center;

}
.advantage-container:last-child{
    justify-content: flex-end;

}
</style>
