<template>
  
      
 
      <div class="flex-wrapper">
  
  <div class="single-chart">
    <svg viewBox="0 0 36 36" class="circular-chart blue">
      <path class="circle-bg"
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      
      <path class="circle"
        :stroke-dasharray="percent +',100'"
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <svg x="14.7" y="8.35" width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="icon-color"  d="M0.449219 3.6833H1.71231V6.44632H0.449219V3.6833ZM4.87005 2.45529H6.13314V6.44632H4.87005V2.45529ZM2.65963 0.613281H3.92273V6.44632H2.65963V0.613281Z" fill=""/>
    </svg>

      <text x="18" y="25.35" class="percentage">{{$toLocal(percent,0)}}%</text>
    </svg>
  </div>
</div>
  
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    percent:Number
  },
  data(){
      return{
        
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.flex-wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  justify-content: center;
  flex-grow:1;

}

.single-chart {
  flex-grow:1;
  justify-content: space-around ;
}

.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 80%;
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 1.1;
}

.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.icon-color{
    fill:var(--wallet-coin-circular-chart-icon)
}
.circular-chart.blue .circle {
  stroke: var(--wallet-coin-circular-chart-border);
}

.percentage {
  fill: var(--wallet-coin-circular-chart-percent);
  font-weight: bold;
  font-family: sans-serif;
  font-size: 10px;
  text-anchor: middle;
}
</style>
