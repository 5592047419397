<template>
<div class="carousel">
    <vueper-slides :breakpoints="breakpoints" class="no-shadow" :visible-slides="5" :arrows="false" :bullets="false" :rtl="true" :autoplay="true" :duration="2000" :slide-ratio="1 / 6" fixedHeight='111px'>
        <vueper-slide v-for="(crypto,index) in cryptos" :key="index" :title="index.toString()">
            <template #content>
                <div class="vueperslide__content-wrapper">
                    <div class="card">
                        <div class="vueperslide__title">
                            <div class="crypto-title">{{($coinLabel[crypto.relatedCoin])}} - {{crypto.marketType.includes('TOMAN')?'تومان':'تتر'}}</div>
                            <div dir="ltr" class="crypto-change">
                                <span v-if="crypto.last24HourChange>0">+</span>
                                <span v-if="crypto.last24HourChange<0">-</span>
                                {{Math.abs(crypto.last24HourChange).toFixed(2)}} %
                            </div>
                            <div class="crypto-symbol">{{crypto.relatedCoin}}</div>
                        </div>
                        <div class="price">{{$toLocal(crypto.lastPrice,crypto.marketType.includes('TOMAN')?0:2)}}</div>
                        <CoinDataChart :data="crypto.lastWeekData" />

                    </div>

                </div>
            </template>
        </vueper-slide>
    </vueper-slides>

</div>
</template>

<script>
import {
    VueperSlides,
    VueperSlide
} from 'vueperslides'
import CoinDataChart from './CoinDataChart'
import 'vueperslides/dist/vueperslides.css'
export default {
    name: 'CryptoCarousel',
    props: {

    },
    components: {
        CoinDataChart,
        VueperSlides,
        VueperSlide
    },
    methods: {
        async getMarkets() {
            let res = await this.$axios.get('/trades/market-info', {
                params: {
                    weeklyDataIncluded: true
                }
            }).then(response => res = response.content)
            if (res) {
                this.cryptos = res
            }
        },
    },
    mounted() {
        this.getMarkets()
    },
    data() {
        return {
            cryptos: [],
            breakpoints: {

                1200: {
                    visibleSlides: 4
                },
                900: {
                    visibleSlides: 3
                },
                600: {
                    visibleSlides: 2,

                },

                400: {
                    visibleSlides: 1,
                },
                // The order you list breakpoints does not matter, Vueper Slides will sort them for you.

            },

        }
    },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.vueperslide__content-wrapper {
    background: var(--crypto-carousel-bg);

}

.card {
    min-height: 100%;
    padding: 16px;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;

    align-content: flex-start;

}

.chart {
    max-width: 176px;
}

.carousel {
    min-height: 111px;
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
}

.vueperslide__title {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
}

.crypto-title {
    font-size: clamp(10px, 1.2vw, 12px);
    color: var(--crypto-carousel-title)
}

.crypto-change {
    font-size: clamp(10px, 1.2vw, 12px);
    color: var(--crypto-carousel-change);
    font-weight: bold;
}

.crypto-symbol {
    font-weight: bold;
    font-size: clamp(14px, 1.6vw, 16px);
    color: var(--crypto-carousel-symbol)
}

.price {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    font-size: clamp(10px, 1.2vw, 12px);
    color: var(--crypto-carousel-price)
}
</style>
