<template>
<div class="carousel-container">
    <vueper-slides
    
    :breakpoints="breakpoints"
    class="no-shadow carousel"
    :visible-slides="3"
    :arrows="true"
    :gap="1"
    :bullets="false"
    :arrowsOutside="false"     
    :rtl="true"
      :slide-ratio="1 / 4"

    >
    <vueper-slide  v-for="(crypto,index) in cryptos" :key="index" :title="index.toString()">
      <template #content>
      <div class="vueperslide__content-wrapper" >
       
        <!-- <img  class="bg" src='../../../assets/public/eventBg.png'/> -->

       

      </div>
    </template>
    </vueper-slide>
    <template #arrow-left>
        <div class="arrow">
            <img src="../../../assets/public/carousel-arrow.svg"/>
        </div>
    </template> 
    <template #arrow-right>
        <div class="arrow reverse-arrow">
            <img src="../../../assets/public/carousel-arrow.svg"/>
        </div>
    </template>
    </vueper-slides>

</div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import variables from '../../../library/variables.json'
export default {
  name: 'CryptoCarousel',
  props: {
   
  },
  data(){
    return{
          cryptos:variables.cryptos,
          breakpoints: {
                 
               
                980: {
                  visibleSlides: 2
                },
              
                
                 550: {
                  visibleSlides: 1,                
                },
                // The order you list breakpoints does not matter, Vueper Slides will sort them for you.
               
              },

    }
  },
   components: { VueperSlides, VueperSlide },
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.vueperslide__content-wrapper{
  background: url('../../../assets/public/eventBg.png');
  background-size:contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  
}
.reverse-arrow{
    transform: rotatez(180deg);
}
.arrow{
    height: 32px;
    width: 32px;
    border-radius: 50%;
   
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--events-carousel-arrow);
}

.bg{
  
  height: auto;
}

.carousel{
    margin-top: 50px;
    width: 100%;
   
    
}

.carousel-container{
    display: flex;
    justify-content: center;
    width: 100%;
}


</style>