<template>
  <AuthCard @close="$emit('authModalToggle','')">
     <template #title>
         فراموشی رمز عبور
    </template>
     <template #body>
         <div class="text">
                کد شش رقمی ارسال شده به آدرس ایمیل خود را وارد نمایید 
         </div>
         <VerifyCodeInput/>
          <button class="auth-submit-btn" @click="$emit('authModalToggle','ForgetPass')">تایید</button>

     </template> 
   

  </AuthCard>
    
</template>

<script>
import VerifyCodeInput from '../tools/VerifyCodeInput'
import AuthCard from './AuthCard'
export default {
  name: 'OtpEmail',
  components:{
      AuthCard,
      VerifyCodeInput
  },
  data(){
    return{
      
    }
  }
}
</script>
<style scoped>
.text{
    font-size: 12px;
    color:var(--modal-text)

}
</style>
