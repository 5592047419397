<template>
    <div   :class="{'App':true,'public-bg':!$route.path.includes('Panel'),'dark':!state.lightTheme,'light':state.lightTheme,'panel-bg':$route.path.includes('Panel')}">
        <Navbar @authModalToggle="(value)=>{authModal=value}"/>
        <Login @authModalToggle="(value)=>authModal=value"  v-if="authModal=='Login'"/>
        <SignUp @authModalToggle="(value)=>authModal=value"  v-if="authModal=='SignUp'"/>
        <OTP @authModalToggle="(value)=>authModal=value"  v-if="authModal=='OTP'"/>
        <ForgetPassEmail @authModalToggle="(value)=>authModal=value"  v-if="authModal=='ForgetPassEmail'"/>
        <ForgetPassOtp @authModalToggle="(value)=>authModal=value"  v-if="authModal=='ForgetPassOtp'"/>
        <ForgetPass @authModalToggle="(value)=>authModal=value"  v-if="authModal=='ForgetPass'"/>

      <router-view  @signupModalToggle="authModal='SignUp'"/>
    <LoadingPage v-if="state.loading"/>

    </div>

</template>
<script>
import LoadingPage from './components/tools/LoadingPage'

import Navbar from './components/Public/Navbar'
import Login from './components/Auth/Login'
import SignUp from './components/Auth/SignUp'
import OTP from './components/Auth/OTP'
import ForgetPassEmail from './components/Auth/ForgetPassEmail'
import ForgetPassOtp from './components/Auth/ForgetPassOtp'
import ForgetPass from './components/Auth/ForgetPass'
export default {
  name:'App',
  components:{
    Navbar,
    Login,
    SignUp,
    OTP,
    LoadingPage,

    ForgetPassEmail,
    ForgetPassOtp,
    ForgetPass

  },
  mounted(){
    console.log('sdfdfasds')
  },
  methods:{
   
  },
//   watch: {
//   $route() {
//     this.$refs.scroll.$el.scrollTop = 0;
//   }
// },
  data(){
    return{
      
      authModal:""
      // $state
    }
  }
}
</script>
<style lang="scss">
.ps{
   min-height: 100vh; 
   height: 100vh;
   width: 100%;
   
   

}

.App{
   min-height: 100vh; 
  flex-direction: column;
  display: flex;
  width: 100%;
  align-items: center;

}
.public-bg{
    background:  var(--background);

}
.panel-bg::after{
  content: '';
    background:  var(--background);
    position: fixed;
    top: 0px;
    left: 0px;
     min-height: 100vh; 
    width: 100vw;
    height: 100%;
    z-index: -2;
}
.panel-bg::before{
   content: '';
   background-image: url('./assets/public/coloryBg1.png');
   background-size: cover;
    position: fixed;
    opacity: 0;
    top: 0px;
    left: 0px;
     min-height: 100vh; 
    width: 100vw;
    height: 100%;
    z-index: -1;
    animation-duration: 3s;
    animation-name: bgChange;
     animation-iteration-count: infinite;
    animation-timing-function: ease;
    animation-direction: alternate;
}
// .App::before{
//    content: '';
//    background-image: url('./assets/public/coloryBg2.png');
//    background-size: cover;
  
//     position: fixed;
//     opacity: 0;
//     top: 0px;
//     left: 0px;
//     width: 100vw;
//      min-height: 100vh; 

//     z-index: -1;
//    animation-duration: 3s;
//    animation-name: bgChange;
//    animation-iteration-count: infinite;
//    animation-direction: alternate;
// }
// .App::before{
//    content: '';
//    background-image: url('./assets/public/coloryBg3.png');
//    background-size: cover;
//     position: fixed;
//     opacity: 0;
//     top: 0px;
//     left: 0px;
//     width: 100vw;
//      min-height: 100vh; 

//     z-index: -1;
//    animation-duration: 3s;
//    animation-name: bgChange;
//    animation-iteration-count: infinite;
//    animation-direction: alternate;
// }


@keyframes bgChange {
  from {opacity: 0;}
  to {opacity:0.3;} 
  
}



</style>
