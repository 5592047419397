<template>
<div>
<div class="public">
      <Terms v-if="$route.name=='Terms'"/>
      <FAQ v-if="$route.name=='FAQ'"/>
      <Commissions v-if="$route.name=='Commissions'"/>
      <AboutUs v-if="$route.name=='AboutUs'"/>
      <ContactUs v-if="$route.name=='ContactUs'"/>
      <HomePage  class="home-page "  v-if="$route.path=='/'" @signupModalToggle="$emit('signupModalToggle')"/>
      <Markets v-if="$route.name=='Markets'"/>
      <Dashboard v-if="$route.name=='Dashboard'"/>
      <div class="chat-icon-container">
        <img src="../../assets/icons/chat-icon.svg" />
      </div>

</div>
  <Footer/>

</div>
</template>

<script>

// import Commissions from './Commissions'
import FAQ from './FAQ'
import Terms from './Terms'
import AboutUs from './AboutUs'
import ContactUs from './ContactUs'
import HomePage from './HomePage/Homepage'
import Markets from './Markets'
import Footer from './Footer'
import Dashboard from '../Panel/Dashboard/Dashboard'

export default {
  name: 'Public',
  components:{
    Footer,
    HomePage,
    FAQ,
    Terms,
    AboutUs,
    ContactUs,
    Markets,
    Dashboard
  },
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.public{
  width: 100%;
  display: flex;
  // flex-direction: column;
  justify-content: center;
  // margin-top: 60px;

}

.chat-icon-container{
  display:flex;
  position: fixed;
  cursor: pointer;
  right: 8vw;
  background:var(--public-chat-icon-bg);
  stroke:var(--public-chat-icon);
  top: 80vh;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  z-index: 10;
  border-radius: 50%;
}
</style>
