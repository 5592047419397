<template>
<div click.self="" class="drop-container">
    <div :class="{dropdown:true,disabled:disabled}">
        <div class="input-container">
            <input placeholder='جستجو' @input="$emit('search',regex)" v-model="regex"/>
            <inline-svg class="icon" :src="require('../../assets/icons/Search.svg')" />
        </div>

    </div>

</div>
</template>

<script>

export default {
    name: 'DropDown',

    props: {
        placeholder: String,
        type: String,
        items: Array,
        defaultValue: String,
        disabled: Boolean
    },
    created() {
        document.addEventListener('click', (e) => {
            if (!e.target.closest('.drop-container')) {
                this.dropOpen = false
            }
        })
    },
    data() {
        return {
            dropOpen: false,
            //   choice:this.items[0],
            regex:''

        }
    },
    methods: {
    
        filterItems() {
            return this.items.filter(item => {
                if (item.value != this.choice.value) {
                    return item
                }
            })

        },
        choose(item) {
            this.dropOpen = false;
            this.choice = JSON.parse(JSON.stringify(item))
            this.$emit('choose', item.value)
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
input {
    background: transparent;
    height: 100%;
    color: var(--label-input-text);
    flex-grow: 1;

}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--label-input-text);
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--label-input-text);
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--label-input-text);
}

.input-container {
    display: flex;
    justify-content: space-between;
    height: 42px;
    align-items: center;

}

.dropdown {
    position: absolute;
    width: 100%;
    padding: 0px 16px;
    border-radius: 20px;
    background: var(--dropdown-bg);
    border: 1px solid var(--dropdown-border);
    color: var(--dropdown-text);

    z-index: 1;
}

.drop-open {
    transform: rotate(180deg);
}

.items {
    height: 0px;
    transition: height 0.8s;

    cursor: pointer;
    max-height: 140px;
    overflow-y: scroll;
}

.drop-container {
    position: relative;
    max-height: 40px;
    height: 40px;
    flex-grow: 1;
    flex-basis: 80px;

}

.field {
    height: 40px;
    font-size: clamp(13px, 1.4vw, 14px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 6px;
}

.disabled {
    background: var(--label-input-disabled);
}

.icon {
    stroke: var(--dropdown-icon);
    cursor: pointer;

}

// input{
//     padding: 0px!important;
//     background: var(--dropdown-bg);
//     height:100%;
//     width: 100%;
//     color: var(--dropdown-text);
//     font-size: clamp(13px,1.4vw,14px);
// }
</style>
