<template>

    <div class="notifications">
        <div v-for="(notification,index) in notifications" :key="index" class="notification" @click="readMessage(notification.id,index)" >
            <div class="notification-header" >
               <div class="title-read"><div :class="{circle:true,read:notification.read,unread:!notification.read }"   /><span>{{notification.title}}</span></div> 
               <div class="date-drop resp"><span>{{$G2J(notification.createdAtDateTime)}}</span><inline-svg :class="{'drop-open':openedIndex==index,'drop-icon':true}"  :src="require('../../assets/icons/arrowdown.svg')"/>  </div>
            </div>
            <div v-if="openedIndex==index" class="content">
                <div>{{notification.content}}</div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
  name: 'Notifications',
  data(){
    return{
        openedIndex:null,
        notifications:[
            ]
      
    }
  },
  methods:{
       async getMessages() {
                const res = await this.$axios.get('/users/notifications',
                    {
                        params: {
                            page: 1, size: 13,
                        }
                    })
                if(res.message !== 'No Notifications'){
                    this.notifications.push(...res.content)
                    this.unreadedCount = res.totalElements
                }
                else{
                    this.notifications = []
                    this.unreadedCount = 0
                }
            },
            async readMessage(id,index) {
                this.openedIndex=(this.openedIndex==index)?null:index;
                if (!this.notifications[index].read){
                    this.notifications[index].read = true
                    await this.$axios.put(`/users/notifications/read/${id}`)
                }
                this.$forceUpdate()
            },
  },
  mounted(){
           this.getMessages()
  }
}
</script>
<style scoped>

.notification{
    background: var(--Notifications-message-bg);
    border-radius: 20px;
    width: 100%;
    padding: 10px 13px;
    cursor: pointer;

}

.notifications{
    display: flex;
    flex-grow: 1;
    border-radius: 20px;
    padding: 16px;
    flex-direction: column;
    row-gap: 12px;
    background: var(--Notifications-page-bg);
}

.content{
    font-size: clamp(10px,1.2vw,13px);
   color: var( --Notifications-message-text);
   word-break: break-all;
   margin-top: 14px;
}
.unread{
    background: #FFBF00;
}
.read{
    border: 1px solid var(--Notifications-readen-border) ;
}
.drop-icon{
    stroke: var(--Notifications-message-icon);
}
.circle{
    width: 14px;
    height: 14px;
    border-radius: 50%;
}
.drop-open{
    transform: rotate(180deg);
}

.notification-header{
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
   
}
.title-read{
    font-size: clamp(11px,1.3vw,14px);
    font-weight: bold;
    color:var(--Notifications-message-title);
    display: flex;
    column-gap: 7px;
    align-items: center;
}

.date-drop{
    color: var(--Notifications-message-date);
    font-size: clamp(11px,1.1vw,12px);
    display: flex;
    column-gap: 20px;
    align-items: center;
}

@media(max-width:382px){
    
    .resp{
        display: none;
    }

}
</style>