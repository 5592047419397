<template>
<div class="homepage">
   <Banner @signupModalToggle="$emit('signupModalToggle')"/>
   <CryptoCarousel/>
   <Events/>
   <Markets/>
   <Advantages/>
   <Support/>
   <Comments/>
   <BlogPosts/>

</div>

   
</template>

<script>
import Banner from './Banner'
import CryptoCarousel from './CryptosCarousel'
import Events from './Events'
import Advantages from './Advantages'
import Markets from './Markets'
import Support from './Support'
import Comments from './Comments'
import BlogPosts from './BlogPosts'
export default {
  name: 'homepage',
  components:{
      Banner,
      CryptoCarousel,
      Events,
      Markets,
      Advantages,
      Support,
      Comments,
      BlogPosts
  },
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.homepage{
  width:85%;
  margin-bottom:200px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  align-items: center;
}
</style>
