<template>
<div class="carousel">
    <vueper-slides
    :breakpoints="breakpoints"
    class="no-shadow"
    :visible-slides="3"
    :arrows="false"
    :bullets="false"
    :rtl="true"
    :autoplay="true"
    :duration="2000"
    draggingDistance='1px'
      :slide-ratio="1 / 6"
      fixedHeight='128px'
    >
    <vueper-slide  v-for="(card,index) in bankAccounts" :key="index" :title="index.toString()">
      <template #content>
      <div class="vueperslide__content-wrapper" >
          <div class="card-container">
            <div :class="{card:true,'gold-card':index%3==0,'purple-card':index%3==1,'pink-card':index%3==2} ">
                <div class="sheba-number">IR&nbsp;{{card.shebaCode}}</div>
                <div class="card-number">{{card.cardNumber}} </div>
                <div class="card-owner">
                    <div class="card-owner-label">دارنده کارت</div>
                    <div class="card-owner-name">{{fullname}}</div>
                </div> 
            </div>
            <div class="icons">
                <inline-svg class="icon" @click="edit(index)" :src="require('../../../assets/icons/edit-square.svg')"/>
                <inline-svg class="icon" @click="removeAccount(index) " :src="require('../../../assets/icons/delete.svg')"/>
            </div>
          </div>
      </div>
    </template>
    </vueper-slide>
    </vueper-slides>

</div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
export default {
  name: 'CardSlider',
  props: {
   bankAccounts:Array,
   fullname:String
  },
  
  data(){
    return{
           cardsBg:[
              'gold',
              'purple',
              'pink'
          ],
          breakpoints: {
                1230: {
                  visibleSlides: 2,
                },
                 650: {
                  visibleSlides: 1,                
                },
              },

    }
  },
  methods:{
      removeAccount(index) {
           if(this.bankAccounts.length>1)
                this.bankAccounts.splice(index, 1)
       },
      edit(index){
          this.$emit('edit',index)
       }
  },
   components: { VueperSlides, VueperSlide },
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.vueperslide__content-wrapper{
}

.card{
  height: 100%;
   padding: 16px;
   display: flex;
   width: 100%;
   flex-direction: column;
   align-items: center;
  
   align-content: flex-start;

}
.card-container{
    display: flex;
    width: 100%;
    align-items: center;
    // justify-content: space-between;
}

.carousel{
  height: 128px;
  width: 100%;
 margin-top: 22px;
}

.card{
    // height: 100%;
    padding: 16px;
    max-width: 228px;
     
  
    display: flex;
    z-index: 1;
    color:#3D3D3D;
    flex-direction: column;
}

.pink-card{
  background: url('../../../assets/public/pink-card.png');
   background-size:contain;
    background-repeat:no-repeat;

}
.purple-card{
  background: url('../../../assets/public/purple-card.png');
  background-size:contain;
  background-repeat:no-repeat;
  color: white!important;;
}

.icons{
    display: flex;
    flex-grow: 1;
    align-items: center;
    flex-direction: column;
    row-gap: 32px;
   

}


.icon{
     stroke:var(--authentication-creditcard-icon);
     cursor: pointer;
}

 .card-owner{
     padding: 5px 5px;
    background: rgba(184, 184, 184, 0.2);
    backdrop-filter: blur(9px);
     border-radius: 10px;
     display: flex;
     width: 100%;
     font-size: clamp(10px,1.1vw,12px);
     justify-content: space-between;
     align-items: center;
 }
 .card-owner-label{
     font-weight: bold;
 }
.gold-card{
  background: url('../../../assets/public/gold-card.png');
   background-size:contain;
    background-repeat:no-repeat;
  
}
.card-digital-sign{
    border: 1px solid;
}

.sheba-number{
    margin-top: 30px;
    font-family: 'YekanLight';
    font-size: 12px;
    text-align: left;
    width: 100%;
}
.card-number{
    flex-grow: 1;
    font-size: 12px;
    text-align: left;
    width: 100%;


}
 @media(max-width:500px){
     .card{
         width:200px
     }
 }
</style>

