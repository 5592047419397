<template>
<div :class="{sidebar:true,'closed-sidebar':!is_open}">
    <div class="head-section">
        <inline-svg v-if="is_open" @click.prevent="$router.push('/Panel/Level')" :class="{'profile-icon':true, 'side-icon':true}" :src="require('../../assets/icons/Profile.svg')" />
        <div v-if="is_open" @click.prevent="$router.push('/Panel/Level')" :class="{'link-title':true,'user-info':true}">
            <div class="user-name">{{state.userInfo.firstName+' '+state.userInfo.lastName}}</div>
            <div class="user-level">سطح {{userLevels[state.userInfo.userAccountLevel].label}}</div>
        </div>
        <div class="btns">
            <inline-svg class="side-icon close-side-icon" v-if="!locked_close" @click="is_open=!is_open" :src="require('../../assets/icons/closeSide.svg')" />
            <inline-svg v-if="is_open" @click.prevent="$router.push('/Panel/Level')" :class="{'side-icon':true, 'user-icon':true}" :src="require('../../assets/icons/Profile.svg')" />
            <inline-svg class="side-icon" :src="require('../../assets/icons/Share.svg')" />
        </div>
    </div>
    <div class="links">
        <a v-for="(link,index) in links" :key="index" :class="{link:true,'link-active':$route.name.includes(link.name)}" @click.prevent="$router.push('/Panel/'+link.path)" :href="'/Panel/'+link.path">
            <inline-svg :class="{icon:true,'link-active':$route.name.includes(link.name)}" :src="require('../../assets/icons/'+link.name+'.svg')" />
            <span v-if="is_open" :class="{'link-title':true}"> {{link.title}}</span>
        </a>
    </div>
    <a class="link" href='/' @click.prevent="exitModalShow=true">
        <inline-svg class="icon " :src="require('../../assets/icons/Logout.svg')" />
        <span v-if="is_open" :class="{'link-title':true}">خروج از حساب کاربری</span>
    </a>
<ExitModal v-if="exitModalShow" @modalToggle="value=>exitModalShow=value"/>
</div>
</template>

<script>
import ExitModal from '../Modals/ExitModal'
export default {
    name: 'sidebar',
    components:{
        ExitModal
    },
    mounted() {

    },

    data() {
        return {
            exitModalShow:false,
            links: [{
                    title: 'داشبورد',
                    name: 'Dashboard',
                    path: ''
                },
                {
                    title: 'پروفایل',
                    name: 'Profile',
                    path: 'Profile'
                },
                {
                    title: 'کیف پول',
                    name: 'Wallet',
                    path: 'Wallet'
                },
                {
                    title: 'تاریخچه',
                    name: 'History',
                    path: 'History'
                },
                {
                    title: 'تنظیمات',
                    name: 'Settings',
                    path: 'Settings'
                },
                {
                    title: 'اعلانات',
                    name: 'Notifications',
                    path: 'Notifications'
                },
                {
                    title: 'دعوت دوستان',
                    name: 'InviteFriends',
                    path: 'InviteFriends'
                },
                {
                    title: 'تیکت',
                    name: 'Ticket',
                    path: 'Ticket'
                },
            ],
            userLevels: {
                'BRONZE': {
                    label: 'اول',
                    value: 1
                },
                'SILVER': {
                    label: 'دوم',
                    value: 2
                },
                'GOLD': {
                    label: 'سوم',
                    value: 3
                },
                'PLATINUM': {
                    label: 'چهارم',
                    value: 4
                },
                'SUPERVISOR': {
                    label: 'پنجم',
                    value: 5
                },
            },
            is_open: true,
            locked_close: false
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.user-info {
    cursor: pointer;
}

.link {
    display: flex;
    column-gap: 12px;
    color: var(--sidebar-link);
    font-family: 'YekanLight';
    font-size: 14px;
    cursor: pointer;
}

.btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 16px;
}

.icon {
    stroke: var(--sidebar-link);
    height: 16px;
    width: 16px;

}

* {
    transition: opacity 0.7s;
}

.user-name {
    font-size: clamp(14px, 1vw, 18px);
    color: var(--sidebar-user);
    font-weight: bold;
}

.user-level {
    font-size: clamp(10px, 1.1vw, 12px);
    color: var(--sidebar-level);
}

.side-icon {
    stroke: var(--sidebar-btn);
    cursor: pointer;
}

.profile-icon {
    height: 24px;
    width: 24px;
}

.links {
    display: flex;
    flex-direction: column;
    row-gap: clamp(24px, 3vw, 36px);
}

.link-active {
    color: var(--sidebar-link-active);
    stroke: var(--sidebar-link-active);
}

.head-section {
    display: flex;
    justify-content: space-between;
}

.sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 20px;
    row-gap: 40px;
    padding: clamp(15px, 2vw, 24px) clamp(12px, 1.6vw, 20px);
    background: var(--sidebar-bg);
    width: 231px;
    min-width: 33px;
    max-height: 660px;
    transition: width 0.7s;
    overflow: hidden;
    white-space: nowrap;
}

.closed-sidebar {
    padding: 24px 16px 22px 16px;
    width: 48px;

}

@media(max-width:870px) {
    .link-title {
        display: none;
    }

    .sidebar {
        align-items: center;
        max-width: 40px;

    }

    .user-icon {
        display: block !important;
    }

    .profile-icon {
        display: none;
    }

    .close-side-icon {
        display: none;
    }
}
</style>
