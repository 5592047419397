<template>

     <AuthCard @close="$emit('authModalToggle','')">
      <template #title> ورود به حساب کاربری</template>
      <template #body>
          <LabelInput @input="value=>{data.email=value;emailError=''}" :errorText="emailError" label="آدرس ایمیل"/>
          <div>
          <LabelInput @input="value=>{data.password=value;}"  type='password' label="رمز عبور"/>
          <div class="forget-pass">
            <a  @click.prevent="$emit('authModalToggle','ForgetPassEmail')" >فراموشی رمز عبور </a>
          </div>    

          </div>
          <div class="captcha-container">
          <img src='../../assets/public/captcha-code.png'/>    
          </div>
          <LabelInput @input="value=>{data.securityCode=value;}"  type='securityCode' label="کد امنیتی"/>

          <div class="btns">
            <button :class="{'auth-submit-btn':true,disabledbtn:!data.email ||!data.password || !data.securityCode  }" 
            :disabled="!data.email ||!data.password || !data.securityCode" @click="send()" >ورود</button>
            <button class="google-btn"  @click="googleSso()">
                ادامه با حساب گوگل
            </button>
            <!-- $emit('authModalToggle','OTP') -->
          </div>
         <div class="suggest">حساب کاربری ندارید؟  <a @click.prevent="$emit('authModalToggle','SignUp')">ثبت نام کنید </a></div>

         
      </template>
     
        
          <!-- <GoogleBtn/> -->
  

  </AuthCard>


 
    
</template>

<script>

import AuthCard from './AuthCard'
  const verifyType = {
        EMAIL: 'Email',
        SMS: 'SMS',
        GOOGLE_AUTHENTICATOR: 'GoogleAuth',
    }
export default {
  name: 'Login',
  components:{
      AuthCard,
  },
  data(){
      return{
           data:{
                    email: '',
                    password: '',
                    osType: '',
                    deviceType: '',
                    browser: '',
                    securityCode:''
                },
                    show: false,
                    emailError: '',
                    passwordError: '',
        }
  },
  methods:{
        googleSso() {
            window.location.href = this.$google_sso
        },
        async send() {

            await this.checkEmail()
            this.state.loading = true
            const res = await this.$axios.post('/users/login', this.data)
            if (res.message === 'login successful, otp is required') {

                this.$cookies.set('username', this.data.email)
                this.state.userInfo.email = this.data.email
                this.state.userInfo.mobilenumber = res.baseDTO.starTwoStepAuthentication || '';
                this.state.userInfo.towStepAuthenticationType=verifyType[res.baseDTO.towStepAuthenticationType]
                // this.$router.push('/TwoStepVerify/' + verifyType[res.baseDTO.towStepAuthenticationType])
                this.$emit('authModalToggle','OTP')

            } else if (res.token) {
                this.$setToken(res.token, this.state.userInfo.remember)
                await this.$router.push('/Panel/Dashboard')
            }

        },
        async checkEmail() {
            if (!this.$validEmail(this.data.email) || !this.data.email) {
                this.$error('اطلاعات وارد شده اشتباه است', 'ایمیل صحیح وارد نمایید')
                return Promise.reject('repeat failed : ' + this.data.email)
            }
        },
        getData() {
            this.data.osType = this.$detectOs()
            this.data.browser = this.$detectBrowser() || 'CHROME'
            this.data.deviceType = this.$detectDevice()
        },
    },
    mounted() {
        this.getData()
    }
}
</script>
<style lang='scss' scoped>
.forget-pass{
    // display: flex;
    // flex-wrap: wrap;
    margin-top: 8px;
    font-size: clamp(10px,1.2vw,12px);
    color: var(--forget-pass-link);
    text-align:left;
    font-weight: 300;

}

.captcha-container{
    display: flex;
    justify-content: center;
}

.btns{
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.suggest{
    display: flex;
    justify-content: center;
    font-size: clamp(10px,1.1vw,12px);
    color:var(--suggest)
}


.suggest > a{
   color:var(--suggest-link)
}

.disabledbtn{
    opacity: 0.6;
}


</style>
