<template>
<div class="dashboard">
    <div class="upper-section">
        <Depsoits/>
        <div class="side-section">
            <div class="total-profit-section">
                <div class="total-profit-title">سود کلی</div>
                <div class="total-profit">
                    <div class="total-profit-percent">{{totalProfitPercent}}%
                        <inline-svg :class="{'negative-arrow':totalProfitPercent<0}" :src="require('../../../assets/icons/value-arrow.svg')" />
                    </div>
                    <div total-profit-value>{{totalProfitValue.toLocaleString()}}</div>
                </div>
            </div>
            <CardsComp />
            <div dir="ltr" class="friends-section">
                <div dir="rtl" class="friends-count-sec">
                    <div class="friends-count">
                        <div>{{inviteData.countInvitees}}</div>
                        <inline-svg :src="require('../../../assets/icons/InviteFriends.svg')" />
                    </div>
                    <div class="friends-label">تعداد دوستان من</div>
                </div>
                <div dir="rtl" class="friends-profit-sec">
                    <div class="friends-label">مجموع سود من</div>
                    <div class="friends-profit">{{$toLocal(inviteData.profit,0)}} تومان</div>
                    <button class="friends-btn" @click="$router.push('InviteFriends')">دعوت دوستان</button>
                </div>
            </div>
        </div>

    </div>
    <DashboardTable/>

</div>
</template>

<script>
import Depsoits from './Depsoits'
import DashboardTable from './DashboardTable'
import CardsComp from '../../tools/CardsComp'
export default {
    name: 'Dashboard',
    components: {
        Depsoits,
        CardsComp,
        DashboardTable
    },
    data() {
        return {
            totalProfitValue: 12875,
            totalProfitPercent: 10,
            inviteData:{}
            
        }
    },
    methods: {
        // async getWallets() {
        //     this.state.loading = true
        //     const [res, ] = await this.$http.get('/wallets/customer-addresses?walletType=P2P')
        //     if (res) {
        //         let entireRes = Object.entries(res)
        //         const sortCoins = this.$walletSortCoins
        //         entireRes.sort((a,b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
        //         this.wallets = entireRes
        //     }
        //     this.loading = false
        // },
        async getData() {

            const res = await this.$axios.get('/users/invites');
            this.inviteData = res.baseDTO;

        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.dashboard {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    row-gap: 18px;
}

.side-section {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 16px;
    column-gap: 16px;
    max-width: 258px;
    flex-basis: 200px;
}

.total-profit-percent {
    color: var(--dashboard-totalProfit-percent);
    stroke: var(--dashboard-totalProfit-percent);
    font-size: clamp(11px, 1.1vw, 12px);
    font-weight: bold;
    display: flex;
    column-gap: 4px;
    align-items: center;
}

.total-profit-value {
    font-size: clamp(16px, 1.7vw, 18px);
    font-weight: bold;

}

.negative-arrow {
    transform: rotate(180deg);
}

.total-profit-section {
    display: flex;
    flex-grow: 1;
    flex-basis: 150px;
    justify-content: space-between;
    background: var(--dashboard-section-bg);
    color: var(--dashboard-totalProfit-text);
    flex-direction: column;
    row-gap: 8px;
    padding: 15px 24px 15px 24px;
    border-radius: 10px;
}

.upper-section {
    display: flex;
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 16px;
}

.total-profit-title {
    font-family: 'YekanLight';
    font-size: clamp(14px, 1.5vw, 16px);
}

.total-profit {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

//friends section

.friends-section {
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    flex-grow: 1;
    padding: 25px 16px;
    column-gap: 10px;
    min-width: 230px;
    background: var(--dashboard-section-bg);
    border-radius: 20px;
    display: flex;

}

.friends-label {
    color: var(--dashboard-friends-label);
    font-size: clamp(12px, 1.2vw, 13px);
    font-family: 'YekanLight';
}

.friends-count {
    display: flex;

    justify-content: space-between;
    color: var(--dashboard-friends-count);
    stroke: var(--dashboard-friends-count);
    font-size: clamp(16px, 1.7vw, 18px);
    font-weight: bold;
}

.friends-profit {
    font-size: clamp(14px, 1.5vw, 16px);
    color: var(--dashboard-friends-profit);
    font-weight: bold;
}

.friends-btn {
    color: #3D3D3D;
    background: #FFBF00;
    border-radius: 50px;
    padding: 4px 16px;
    font-size: clamp(13px, 1.4vw, 14px);
    font-family: 'YekanLight';
}

.friends-profit-sec {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.friends-count-sec {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    justify-content: flex-end;
}

@media(max-width:1270px) {
    .side-section {
        max-width: 1000px;
        flex-direction: row;
    }
}
</style>
